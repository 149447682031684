import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MergingWasteMapping, TrashType } from "constants/trash";
import { applySortingRules, getDisplayedErrorsClasses, getMergingWasteMapping } from "helpers/trash";
import { CollectionType } from "models/Collection";
import Partner from "models/Partner";

type SortingRulesContext = {
    sortingRules: Partner["sortingRules"];
    errorsClasses: TrashType[];
    mergingMapping: MergingWasteMapping;
    displayedErrors: TrashType[];
    selectedWasteStream: CollectionType | null;
}

const initialState: SortingRulesContext = {
    sortingRules: {},
    errorsClasses: [],
    mergingMapping: {},
    displayedErrors: [],
    selectedWasteStream: null,
}

function applySortingRulesToState(state: SortingRulesContext) {
    if (state.selectedWasteStream) {
        const errorsClasses = applySortingRules(state.sortingRules, state.selectedWasteStream);
        const mergingMapping = getMergingWasteMapping();
        state.errorsClasses = errorsClasses;
        state.mergingMapping = mergingMapping;
        state.displayedErrors = getDisplayedErrorsClasses(errorsClasses, mergingMapping);
    }
}

export const sortingRulesSlice = createSlice({
    name: 'sorting_rules',
    initialState: initialState,
    reducers: {
        setRules: (state, { payload: sortingRules }: PayloadAction<Partner["sortingRules"]>) => {
            state.sortingRules = sortingRules;
            applySortingRulesToState(state);
        },
        selectWasteStream: (state, { payload: wasteStream }: PayloadAction<CollectionType>) => {
            state.selectedWasteStream = wasteStream;
            applySortingRulesToState(state);
        },
    }
});


export const SortingRulesActions = sortingRulesSlice.actions;

const SortingRulesReducer = sortingRulesSlice.reducer;

export default SortingRulesReducer;