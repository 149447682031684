import { Polygon } from "constants/types";
import BatchAIResult, { BatchAIResultDb } from "./BatchAIResult";
import { AppDispatch } from "store/store";
import { TrashType } from "constants/trash";
import { MediaOverlayActions } from "store/reducers/batches/media_overlay";
import { WastesInputsActions } from "store/reducers/batches/wastes_inputs";

/**
 * Bounding box data serialized for saving in Firestore
 */
export type BoundingBoxDb = Omit<BatchAIResultDb, "points"> 

/**
 * Bounding box data deserialized
 */
export type BoundingBox = Omit<BatchAIResult, "points"> & {
    hidden: boolean;
    createdAt?: number;
};

/**
 * Prefix to the IDs of the bounding boxes HTML elements
 */
export const BBOX_ID_PREFIX = "bounding-box-";

/**
 * Suffix to the IDs of the bounding boxes labels HTML elements
 */
export const BBOX_LABEL_SUFFIX = "-label";

/**
 * Turns an array of BoundingBoxDb into an array of BoundingBox
 */
export function deserializeBoundingBox(batchID: string, bboxes: BoundingBoxDb[] | undefined): BoundingBox[] | undefined {
    return bboxes?.map((bbox, index) => ({ 
        ...bbox, 
        ID: `${BBOX_ID_PREFIX}${batchID}-${index}`, 
        box: JSON.parse(bbox.box),
        hidden: false,
    }));
}

/**
 * Calculate the area of a bounding box relative to the total image size.
 * @returns The portion of the image covered by the box, between 0 and 1
 */
export function getBoundingBoxSurface(box: Polygon, imageWidth: number, imageHeight: number): number {
    const boxWidth = box[1][0] - box[0][0];
    const boxHeight = box[1][1] - box[0][1];
    const boxArea = boxWidth * boxHeight;
    const imageArea = imageWidth * imageHeight;
    if (!imageArea) alert("WARNING: Division by 0, please contact the IT support");
    return boxArea / imageArea;
}

/**
 * Exchange the type of waste of a bounding box.
 * It decrements the count for the old class,
 * and increments it for the new class.
 */
export const changeBoundingBoxTrashType = (bbox: BoundingBox, newType: TrashType, imageWidth: number, imageHeight: number) => (dispatch: AppDispatch) => {
    dispatch(MediaOverlayActions.updateOne({
        id: bbox.ID,
        changes: {
            class: newType,
        },
    }));

    // decrement count of instance from this trash type
    dispatch(WastesInputsActions.exchangeOne({
        oldType: bbox.class,
        newType: newType,
    }));
};

/**
 * Delete an existing bounding box.
 * It decrements the count for the box's class.
 */
export const deleteBoundingBox = (bbox: BoundingBox, imageWidth: number, imageHeight: number) => (dispatch: AppDispatch) => {
    // decrement count of instance from this trash type
    dispatch(WastesInputsActions.decrementOne({
        trashType: bbox.class,
    }));

    // remove box from drawing layer
    dispatch(MediaOverlayActions.removeOne(bbox.ID));
}