import { Checkbox, ListItem, ListItemText } from '@mui/material';
import { UserData } from 'models/User';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SelectedMissionActions } from 'store/reducers/missions/selected';

type AssignAmbassadorListItemProps = {
    ambassador: UserData;
};

const AssignAmbassadorListItem: React.FC<AssignAmbassadorListItemProps> = ({ ambassador }) => {
    const dispatch = useAppDispatch();
    const assigned = useAppSelector(state => state.missions.selectedMission.data?.assigned);

    const handleCheckboxChange = useCallback(() => {
        if (!assigned) return;

        const updatedAmbassadors = assigned || [];
        const isAssigned = updatedAmbassadors.some(a => a.email === ambassador.email);

        const newAmbassadors = isAssigned
            ? updatedAmbassadors.filter(a => a.email !== ambassador.email)
            : [...updatedAmbassadors, ambassador];

        dispatch(SelectedMissionActions.updateAmbassadors(newAmbassadors));
    }, [dispatch, assigned, ambassador]);

    return (
        <ListItem sx={{ padding: 0 }} key={ambassador.email}>
            <Checkbox
                disableRipple={true}
                sx={{ paddingLeft: 0, paddingRight: 1 }}
                checked={assigned?.some(a => a.email === ambassador.email) || false}
                onChange={handleCheckboxChange}
            />
            <ListItemText primary={ambassador.name} />
        </ListItem>
    );
};

export default AssignAmbassadorListItem;
