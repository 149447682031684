import { Box, Typography, useTheme } from '@mui/material';
import {
    SentimentSatisfiedAltRounded,
    SentimentNeutralRounded,
    SentimentDissatisfiedRounded,
} from '@mui/icons-material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { MoodStats, ResponsivenessLevel } from 'models/Missions';

interface MoodIcon {
    value: keyof MoodStats;
    icon: JSX.Element;
    color: string;
}

const MoodSection = ({ moodStats }: { moodStats: MoodStats }) => {
    const { t } = useTranslation([Namespace.MISSIONS]);
    const theme = useTheme();

    const moodIcons: MoodIcon[] = [
        {
            value: ResponsivenessLevel.Responsive,
            icon: <SentimentSatisfiedAltRounded sx={{ padding: 0 }} fontSize="inherit" />,
            color: theme.palette.success.main,
        },
        {
            value: ResponsivenessLevel.Neutral,
            icon: <SentimentNeutralRounded sx={{ padding: 0 }} fontSize="inherit" />,
            color: theme.palette.warning.main,
        },
        {
            value: ResponsivenessLevel.NotResponsive,
            icon: <SentimentDissatisfiedRounded sx={{ padding: 0 }} fontSize="inherit" />,
            color: theme.palette.error.main,
        },
    ];

    // Find the mood with the highest value
    const maxMood = Object.entries(moodStats).reduce(
        (max, [mood, value]) =>
            value > max.value
                ? { mood: mood as ResponsivenessLevel, value }
                : max,
        { mood: ResponsivenessLevel.Responsive, value: 0 }
    );

    // Filter out the max mood to get the other moods
    const otherMoods = moodIcons.filter((mood) => mood.value !== maxMood.mood);

    // Arrange moodIcons to place the max mood in the center
    const sortedMoodIcons = [
        otherMoods[0],
        moodIcons.find((mood) => mood.value === maxMood.mood)!,
        otherMoods[1],
    ];


    // Get the translated responsiveness level
    const responsivenessLevel = t(`responsiveness.${maxMood.mood}`, {
        ns: Namespace.MISSIONS,
    });

    return (
        <Box display="flex" flexDirection="column">
            <Typography variant="h5">
                {t(`responsiveness.title`, { ns: Namespace.MISSIONS })}
            </Typography>
            <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                marginBottom="16px"
            >
                {sortedMoodIcons.map((mood) => (
                    <Box
                        key={mood.value}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Box
                            sx={{
                                color: mood.color,
                                fontSize: mood.value === maxMood.mood ? '96px' : '48px',
                                height: mood.value === maxMood.mood ? '106px' : '58px',
                            }}
                        >
                            {mood.icon}
                        </Box>
                        <Typography
                            sx={{
                                fontSize: mood.value === maxMood.mood ? '16px' : '12px',
                                fontWeight: 900,
                            }}
                            padding={0}
                            align="center"
                            color={mood.color}
                        >
                            {moodStats[mood.value] != null ? moodStats[mood.value] : '0'}%
                        </Typography>
                    </Box>
                ))}
            </Box>
            <Typography sx={{ fontSize: '16px', fontWeight: 500 }} textAlign="center">
                {t(`responsiveness.description`, {
                    ns: Namespace.MISSIONS,
                    responsiveness_level: responsivenessLevel,
                })}
            </Typography>
        </Box>
    );
};

export default MoodSection;
