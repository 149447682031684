import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Route from "models/Route";
import { LoadableContext } from "store/store";

type SelectedRouteContext = LoadableContext & {
    data: Route | null;
};

const initialState: SelectedRouteContext = {
    loading: false,
    error: null,
    data: null,
};


export const selectedRouteSlice = createSlice({
    name: 'selected_Route',
    initialState: initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        selectRoute: (state, { payload: Route }: PayloadAction<Route | null>) => {
            state.loading = false;
            state.data = Route;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const SelectedRouteActions = selectedRouteSlice.actions;

const SelectedRouteReducer = selectedRouteSlice.reducer;

export default SelectedRouteReducer;