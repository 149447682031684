import ChangeCollectionTypeDialog from './ChangeCollectionTypeDialog';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import ActionButton from 'components/_include/Buttons/ActionButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Namespace } from 'locales/translations';

export default function ChangeCollectionTypeButton() {
    const { t } = useTranslation([Namespace.ACTIONS]);

    const [changeTypeDialogOpen, setChangeTypeDialogOpen] = useState(false);

    return (
        <>
            <ActionButton
                color='error'
                onClick={() => setChangeTypeDialogOpen(true)}
                startIcon={<SwapHorizRoundedIcon />}
            >
                {t("change_waste_stream", { ns: Namespace.ACTIONS })}
            </ActionButton>

            <ChangeCollectionTypeDialog
                open={changeTypeDialogOpen}
                onClose={() => setChangeTypeDialogOpen(false)}
            />
        </>
    )
}