import { TrashCount } from "../constants/trash";
import { Timestamp } from "firebase/firestore";
import { CollectionScheduleDbData } from "./CollectionSchedule";
import { BinsCounts } from "./Batch";


export enum CollectionType {
    /** @deprecated Former "IGNORE" */
    HOUSEHOLD_WASTE = "household_waste",

    /** General packaging (plastic, metal, cardboard) collection. */
    SORTABLE_WASTE = "sortable_waste",

    /** Household waste after sorting collection. */
    RESIDUAL_WASTE = "residual_waste",

    /** Organic and green waste collection. */
    BIOWASTE = "biowaste",

    /** Glass objects collection (bottles, jars...). */
    GLASS_WASTE = "glass_waste",

    /** Hidden collection not to be displayed to client. */
    HIDDEN = "hidden",
}

const HIDDEN_COLLECTION_TYPES = [CollectionType.HOUSEHOLD_WASTE, CollectionType.HIDDEN];

/** Types of collections to be displayed to the client. */
export const DISPLAYED_COLLECTION_TYPES = Object.values(CollectionType).filter(type => !HIDDEN_COLLECTION_TYPES.includes(type));

type CollectionScheduleData = Pick<CollectionScheduleDbData, "label" | "route" | "truck"> & {

};

export type CollectionDbData = {
    /** ID of the partner owning the truck recording this collection */
    partnerID: string;

    /** Exact starting time of the collection */
    startAt: Timestamp;

    /** Timestamp of the last batch of the collection */
    endAt?: Timestamp;

    /** Sum of the quantity of each class of waste detected in all the collection's batches */
    results: TrashCount;

    /** 
     * @deprecated
     * Sum of the surfaces of each class of waste detected in all the collection's batches 
     */
    surfaces: Partial<TrashCount>;

    /** Type of waste collected (sortable, OM...) */
    type: CollectionType;

    /** Total number of images displayed to client for this collection */
    batchesCount: number;

    /** Total number of images with error displayed to client for this collection */
    batchesWithErrorCount?: number;

    /** Number of bins raised during this collection, by position/type (left, right, large, lifted by hand...) */
    binsCounts?: BinsCounts;

    /** Schedule this collection is attached to (e.g. Tuesday from 4am to 8am...) */
    schedule: CollectionScheduleData;

    /** True if validators are done verifying this collection, false otherwise */
    processed?: boolean;

    /** Number of batches left to send, queueing on the sensor */
    batchesLeft?: number;
}

type MutatedColletionData = {
    startAt: number;
    endAt?: number;
    results: Partial<TrashCount>;
}

type Collection = Omit<CollectionDbData, keyof MutatedColletionData> & MutatedColletionData & {
    ID: string;
    truckID: string;
    loading: boolean;
}

export default Collection;