import { getDisplayedErrors, mergeTrashCounts } from "helpers/trash";
import { max, sum } from "lodash";
import { useMemo } from "react";
import { useAppSelector, useSortingRules } from "store/hooks";
import { Namespace } from 'locales/translations';
import { WASTES_COLORS, TrashType } from "constants/trash";
import { useTranslation } from "react-i18next";
import MostFrequentErrorsBarsChart, { BAR_COLORS, ERROR_CATEGORY, ErrorPoint, TEXT_COLORS } from "components/_include/Charts/MostFrequentErrors/MostFrequentErrorsBarsChart";
import { Box, Divider, Grid } from "@mui/material";
import ChartTitle from "./ChartTitle";
import { useSpacing } from "constants/theme";
import SectionLoader from "components/_include/SectionLoader";
import MostFrequentErrorsPieChart, { WasteSlice } from "components/_include/Charts/MostFrequentErrors/MostFrequentErrorsPieChart";

type ChartProps = {
    height: number;
}

export default function MostFrequentErrorsChart({ height }: ChartProps) {
    const wastesCounts = useAppSelector(state => state.stats.data?.wastesCounts);

    const { t } = useTranslation([Namespace.CHARTS, Namespace.WASTES]);

    const { errorsClasses, mergingMapping, displayedErrors } = useSortingRules();

    const loading = useAppSelector(state => state.trucks.list.loading
        || state.areas.list.loading || state.stats.loading || state.collections.list.loading);

    const data: ErrorPoint[] = useMemo(() => {
        let errorsTypes: ErrorPoint[] = [];
        if (!wastesCounts) return errorsTypes; // results not loaded yet

        let mergedResults = getDisplayedErrors(errorsClasses, mergingMapping, wastesCounts);

        const maxErrorCount = max(Object.entries(mergedResults).map(([_, count]) => count))!;

        for (const errorType of displayedErrors) {
            const count = mergedResults[errorType] ?? 0;

            const portion = count / maxErrorCount;
            let category: ERROR_CATEGORY;
            if (portion > 0.8) category = ERROR_CATEGORY.WORST;
            else if (portion > 0.4) category = ERROR_CATEGORY.BAD;
            else if (portion > 0.01) category = ERROR_CATEGORY.MEDIUM;
            else {
                category = ERROR_CATEGORY.GOOD;
                // continue;
            }

            errorsTypes.push({
                trashType: errorType,
                count,
                color: BAR_COLORS[category],
                textColor: TEXT_COLORS[category],
            });
        }

        return errorsTypes.sort((p1, p2) => p1.count - p2.count); // sort by items count desc
    }, [wastesCounts]);

    const pieData: WasteSlice[] = useMemo(() => {
        let wastesList: WasteSlice[] = [];
        if (!wastesCounts) return wastesList; // results not loaded yet

        let wastes = mergeTrashCounts(mergingMapping, wastesCounts);

        let mergedResults = getDisplayedErrors(errorsClasses, mergingMapping, wastesCounts);

        let totalErrors = sum(Object.values(mergedResults));

        for (const errorType of displayedErrors) {
            const count = wastes[errorType] ?? 0;

            const portion = count / totalErrors;

            let category: ERROR_CATEGORY;
            if (portion > 0.8) category = ERROR_CATEGORY.WORST;
            else if (portion > 0.5) category = ERROR_CATEGORY.BAD;
            else if (portion > 0.01) category = ERROR_CATEGORY.MEDIUM;
            else category = ERROR_CATEGORY.GOOD;

            const value = count / totalErrors * 100;
            const rounderValue = Math.round(value);
            if (rounderValue > 0) {
                wastesList.push({
                    id: errorType,
                    label: t(errorType, { ns: Namespace.WASTES }),
                    value: rounderValue,
                    color: WASTES_COLORS[errorType as TrashType],
                });
            }
        }

        return wastesList;
    }, [t, wastesCounts]);

    const chartBarsHeight = useSpacing(data.length * 4);
    const heightPixels = useSpacing(height);

    return (
        <Box
            justifyContent="center"
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ChartTitle>
                    {t("most_frequent_errors.title", { ns: Namespace.CHARTS })}
                </ChartTitle>
            </Grid>
            <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="stretch"
                minHeight={height}
            >
                <Grid container>
                    <Grid
                        item
                        md={12}
                        lg={5}
                        height={Math.max(chartBarsHeight, heightPixels)}
                        position="relative"
                    >
                        <MostFrequentErrorsPieChart data={pieData} />
                        {loading && <SectionLoader />}
                    </Grid>

                    <Grid
                        item
                        sx={{
                            display: { xs: "none", md: "flex" }
                        }}
                        md={2}
                        justifyContent="center"
                    >
                        <Divider orientation="vertical" flexItem variant="middle" />
                    </Grid>

                    <Grid
                        item
                        height={Math.max(chartBarsHeight, heightPixels)}
                        md={12}
                        lg={5}
                        overflow="scroll"
                        position="relative"
                    >
                        <MostFrequentErrorsBarsChart data={data} />
                        {loading && <SectionLoader />}
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}