import { ComputedBarDatum } from '@nivo/bar'
import ErrorBarItem from './ErrorBarItem'
import { ErrorPoint } from './MostFrequentErrorsBarsChart';

type LayerProps = {
    interactive?: boolean;
    bars: ComputedBarDatum<ErrorPoint>[];
    mx: number;
}

function ErrorBarsLayer({ interactive, bars, mx }: LayerProps) {
    return (
        <g>
            {bars.map(({ width, height, y, data, color }) => (
                <ErrorBarItem 
                    key={data.data.trashType}
                    interactive={interactive}
                    width={width}
                    height={height}
                    y={y}
                    data={data.data}
                    color={color}
                    mx={mx}
                    />
            ))}
        </g>
    );
}

export default ErrorBarsLayer;