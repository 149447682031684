import { Box, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { Trans } from 'react-i18next';

const ResidentsSpokenSection = ({ percentageVisited }: { percentageVisited: number }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
            textAlign="center"
        >
            <Trans
                i18nKey="able_to_meet"
                ns={Namespace.MISSIONS}
                values={{ count: `${percentageVisited}%` }}
                components={{
                    before: (
                        <Typography sx={{ fontSize: '16px', fontWeight: 500 }} />
                    ),
                    count: (
                        <Typography sx={{ fontSize: '64px', fontWeight: 900 }} />
                    ),
                    after: (
                        <Typography sx={{ fontSize: '16px', fontWeight: 500 }} />
                    ),
                }}
            />
        </Box>
    );
};

export default ResidentsSpokenSection;
