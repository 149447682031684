import { Grid, GridProps, IconButton } from '@mui/material';
import DatesSelect from 'components/_include/Filters/DatesSelect';
import TrucksSelect from 'components/_include/Filters/TruckSelect';
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { DatesRangeType, getDatesFromDateSelectType, isSingleDay } from 'helpers/dates';
import moment from 'moment';
import { Timestamp } from '@firebase/firestore';
import HiddenSelect, { DISPLAY_BATCHES } from './HiddenSelect';
import CachedIcon from '@mui/icons-material/Cached';
import { useAppDispatch, useAppSelector, useSortingRules, usePartnerID } from 'store/hooks';
import AnnotatedSelect, { VERIFIED_BATCHES } from './AnnotatedSelect';
import SingleTrashTypeSelect from './TrashTypeSelect/SingleTrashTypeSelect';
import { unmergeTrashType } from 'helpers/trash';
import TimesRangeSelect from './TimeSelect/TimesRangeSelect';
import BatchesController from 'controllers/batches';
import { TrashType } from 'constants/trash';
import { SearchParam } from 'constants/urls';

type FiltersProps = {
    loadingLimit: number;
}

const FilterWrapper = (props: GridProps) => (
    <Grid
        item
        justifyContent="center"
        display="flex"
        {...props}
    />
);

function BatchesFilters(props: FiltersProps) {
    const { loadingLimit } = props;

    const [searchParams] = useSearchParams();
    const truckID = searchParams.get(SearchParam.TRUCK_ID);
    const dateSelect = searchParams.get("dateSelect");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const startTime = searchParams.get("startTime");
    const endTime = searchParams.get("endTime");
    const hidden = searchParams.get("hidden");
    const annotated = searchParams.get("annotated");
    const trashType = searchParams.get("trashType");
    
    const partnerID = usePartnerID();
    
    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const { mergingMapping, } = useSortingRules();

    const loading = useAppSelector(state => state.batches.list.loading);

    const dispatch = useAppDispatch();

    /**
     * Is only a single day selected? 
     * (then we display the Times range select)
     */
    const singleDaySelected = useMemo(() => {
        if (!dateSelect) return false;
        return isSingleDay(dateSelect as DatesRangeType, moment(startDate), moment(endDate));
    }, [dateSelect, startDate, endDate]);

    const getBatches = () => {
        if (!partnerID || !wasteStream) return;

        let filters: Parameters<typeof BatchesController.list>[1] = [{
            fieldPath: "collectionType",
            opStr: "==",
            value: wasteStream,
        }];

        if (truckID) {
            filters.push({
                fieldPath: "truckID",
                opStr: "==",
                value: truckID?.toString(),
            });
        }

        if (dateSelect) { // dates filter
            let start: moment.Moment | undefined;
            let end: moment.Moment | undefined;
            if (dateSelect === DatesRangeType.CUSTOM) { // custom range
                const startStr = startDate?.toString();
                const endStr = endDate?.toString();
                start = startStr ? moment(startStr) : undefined;
                end = endStr ? moment(endStr) : undefined;
            }
            else { // predefined range (eg "last month"...)
                const dates = getDatesFromDateSelectType(dateSelect.toString() as DatesRangeType);
                start = dates.start;
                end = dates.end;
            }

            if (start) {
                if (singleDaySelected && startTime) { // start at a specific time
                    const [startHours, startMinutes] = startTime.split(":");   
                    start.set("hours", Number(startHours)).set("minutes", Number(startMinutes));
                }
                filters.push({
                    fieldPath: "timestamp",
                    opStr: ">=",
                    value: Timestamp.fromDate(start.toDate()),
                });
            }
            if (end) {
                if (singleDaySelected && endTime) { // stop at a specific time
                    const [endHours, endMinutes] = endTime.split(":");   
                    end.set("hours", Number(endHours)).set("minutes", Number(endMinutes));
                }
                else { // stop at the end of the day
                    end = end.endOf("day");
                }
                filters.push({
                    fieldPath: "timestamp",
                    opStr: "<=",
                    value: Timestamp.fromDate(end.toDate()),
                });
            }
        }

        if (hidden !== DISPLAY_BATCHES.SHOW_BOTH) { // display filter
            filters.push({
                fieldPath: "display",
                opStr: "==",
                value: hidden !== DISPLAY_BATCHES.ONLY_HIDDEN,
            });
        }

        if (annotated && annotated !== VERIFIED_BATCHES.SHOW_BOTH) { // annotated filter
            filters.push({
                fieldPath: "verified",
                opStr: "==",
                value: annotated === VERIFIED_BATCHES.ONLY_ANNOTATED,
            });
        }

        const selectedTrashTypes = trashType ? unmergeTrashType(mergingMapping, trashType as TrashType) : [];

        dispatch(BatchesController.list(partnerID, filters, selectedTrashTypes, { fieldPath: "timestamp", directionStr: "asc" }, loadingLimit));
    };

    // reload collections when filters change
    useEffect(() => {
        getBatches();
    }, [partnerID, truckID, dateSelect, startDate, endDate, startTime, endTime, hidden, annotated, trashType, wasteStream]);

    return (
        <Grid
            container
            display="flex"
            spacing={1}
        >
            <FilterWrapper>
                <Grid container direction="column" spacing={1}>
                    <Grid item>
                        <DatesSelect
                            parentLoading={loading}
                            defaultValue={DatesRangeType.CUSTOM}
                        />
                    </Grid>

                    {singleDaySelected && (
                        <Grid item>
                            <TimesRangeSelect
                                parentLoading={loading}
                            />
                        </Grid>
                    )}
                </Grid>
            </FilterWrapper>
            <FilterWrapper>
                <TrucksSelect
                    parentLoading={loading}
                    needDefault={false}
                />
            </FilterWrapper>
            <FilterWrapper>
                <HiddenSelect
                    parentLoading={loading}
                />
            </FilterWrapper>
            <FilterWrapper>
                <AnnotatedSelect
                    parentLoading={loading}
                />
            </FilterWrapper>
            <FilterWrapper>
                <SingleTrashTypeSelect
                    parentLoading={loading}
                />
            </FilterWrapper>
            <FilterWrapper
                display="inline-block"
            >
                <IconButton
                    onClick={() => getBatches()}
                >
                    <CachedIcon />
                </IconButton>
            </FilterWrapper>
        </Grid>
    );
}

export default BatchesFilters;