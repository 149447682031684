import { Container, Divider } from '@mui/material';
import PageTitle from 'components/_include/PageTitle';
import MissionsGrid from './MissionsGrid';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';
import MissionsMethods from 'controllers/mission';
import MissionsReportStats from './MissionsReportStats/MissionReportStats';
import SectionLoader from 'components/_include/SectionLoader';

export default function MissionsPage() {
    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.missions.missionsList.loading);
    useEffect(() => {
        if (!partnerID) return;
        //load the list of mission for the selected partner
        dispatch(MissionsMethods.getMissions(partnerID));
    }, []);
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <PageTitle />
            {loading ? (
                <SectionLoader />
            ) : (
                <>
                    <MissionsReportStats />
                    <Divider sx={{ my: '24px' }} />
                    <MissionsGrid />
                </>
            )}
        </Container>
    );
}
