import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import SectionLoader from '../SectionLoader';
import ContainedDropdownMenu from './ContainedDropdownMenu';
import OutlinedDropdownMenu from './OutlinedDropdownMenu';
import { OPTION_GROUP_STYLE } from 'helpers/forms';

export type Option = {
    label: string;
    value: string;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
}

export type OptionGroup = {
    label: string;
}

/** Props for all dropdowns */
type CommonProps = {
    id: string;
    title: string;
    loading?: boolean;
    values: (Option | OptionGroup)[];
    defaultValue: string;
    startIcon?: JSX.Element;
    disabled?: boolean;
    onChange: (value: string) => void;
    fullWidth?: boolean;
}

/** Props for contained dropdowns */
type ContainedProps = {
    variant?: "contained";
}

/** Props for outlined dropdowns */
type OutlinedProps = {
    variant: "outlined";
}

type DropdownMenuProps = CommonProps & (ContainedProps | OutlinedProps);

/**
 * Dropdown menu to select a single option.
 * The button to open the menu can be displayed as "contained" (with shadow)
 * or "outlined" (with border)
 */
function DropdownMenu({ id, variant, title, disabled, loading, defaultValue, values, onChange, startIcon, fullWidth }: DropdownMenuProps) {
    const theme = useTheme();

    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);

    // close menu every time a new date is selected
    useEffect(() => {
        onChange(value);
        setOpen(false);
    }, [value]);

    const getMenuLabel = () => {
        if (!value) return title;
        const selectedOption = values.find(v => "value" in v && v.value === value);
        return selectedOption?.label || title;
    }

    return (
        <Box
            display="inline-block"
            position="relative"
            sx={{
                pointerEvents: "all",
                ...(fullWidth && { width: "100%" }),
            }}
        >
            {loading && (
                <SectionLoader size={theme.spacing(3)} />
            )}

            {variant === "outlined" ? (
                <OutlinedDropdownMenu
                    ref={anchorRef}
                    menuId={id}
                    disabled={disabled}
                    label={getMenuLabel()}
                    open={open}
                    onToggleMenu={setOpen}
                    startIcon={startIcon}
                />
            ) : (
                <ContainedDropdownMenu
                    ref={anchorRef}
                    menuId={id}
                    disabled={disabled}
                    label={getMenuLabel()}
                    open={open}
                    onToggleMenu={setOpen}
                    startIcon={startIcon}
                />
            )}

            <Menu
                id={id}
                anchorEl={anchorRef.current}
                open={open}
                onClose={() => setOpen(false)}
                MenuListProps={{
                    'aria-labelledby': `open-${id}`,
                }}
            >
                {values.map((option: Option | OptionGroup) => (
                    <MenuItem
                        key={option.label}
                        selected={"value" in option && option.value === value}
                        onClick={(e) => "value" in option ? onChange(option.value) : {}}
                        {...("value" in option ? { // normal option
                            "data-value": option.value,
                        } : { // option group
                            ...OPTION_GROUP_STYLE,
                        })}
                        sx={{
                            alignItems: "center"
                        }}
                    >
                        {"startAdornment" in option && (
                            <Box component="span" display="flex" mr={1}>
                                {option.startAdornment}
                            </Box>
                        )}
                        {option.label}
                        {"endAdornment" in option && (
                            <Box component="span" display="flex" ml={1}>
                                {option.endAdornment}
                            </Box>
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

export default DropdownMenu;