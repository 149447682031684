import { Card, CardContent, Typography } from '@mui/material';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';


function ReportNotAvailableCard() {
    const { t } = useTranslation([Namespace.MISSIONS]);

    return (
        <Card elevation={0} sx={{ width: '100%', minHeight: '88px', paddingY: '32px', paddingX: '40px', border: '1px solid', borderColor: 'grey.300', borderRadius: 5 }}>
            <CardContent sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}>
                <Typography variant="body2" color="GrayText">
                    {t('report_not_available', { ns: Namespace.MISSIONS })}
                </Typography>
            </CardContent>
        </Card>
    );
}

export default ReportNotAvailableCard;
