import { BoxProps } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import ErrorBarsLayer from './ErrorBarsLayer';
import { red, orange, yellow, green, brown } from "@mui/material/colors";


export enum ERROR_CATEGORY {
    WORST,
    BAD,
    MEDIUM,
    GOOD,
};

export const BAR_COLORS = {
    [ERROR_CATEGORY.WORST]: red[400],
    [ERROR_CATEGORY.BAD]: orange[500],
    [ERROR_CATEGORY.MEDIUM]: yellow[400],
    [ERROR_CATEGORY.GOOD]: green[400],
};

export const TEXT_COLORS = {
    [ERROR_CATEGORY.WORST]: "#ffffff",
    [ERROR_CATEGORY.BAD]: "#ffffff",
    [ERROR_CATEGORY.MEDIUM]: brown[400],
    [ERROR_CATEGORY.GOOD]: green[600],
};

export type ErrorPoint = {
    trashType: string;
    count: number;
    color: string;
    textColor: string;
};

type ChartProps = Pick<BoxProps, | "px"> & {
    data: ErrorPoint[];
    interactive?: boolean;
}

const MARGIN_HORIZONTAL = 2;

export default function MostFrequentErrorsBarsChart({ data, interactive }: ChartProps) {

    return (
        <ResponsiveBar  //horizontal bar chart
            data={data}
            keys={['count']}
            layout="horizontal"
            indexBy="trashType"
            margin={{ top: 0, right: MARGIN_HORIZONTAL, bottom: 0, left: MARGIN_HORIZONTAL }}
            colors={({ id, data }) => data.color}
            // valueFormat={value => `${value} m\u00b2`}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableLabel={false}
            layers={[
                // 'bars',
                // custom layer for labels
                ({ bars }) => (
                    <ErrorBarsLayer
                        bars={bars}
                        interactive={interactive}
                        mx={MARGIN_HORIZONTAL}
                    />
                )
            ]}
        />
    );
}