import { Checkbox, ListItemButton, ListItemText, useTheme } from "@mui/material";
import { EntityId } from "@reduxjs/toolkit";
import { DbCollection } from "constants/db";
import { SensitizationAddresses } from "constants/types";
import { collection, CollectionReference, doc, DocumentData, getFirestore } from "firebase/firestore";
import { formatAddress } from "helpers/geo";
import { Namespace } from "locales/translations";
import _ from "lodash";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";
import { getBatchKey } from "store/reducers/batches/places";
import { selectSortingPointByAddressKey } from "store/reducers/batches/sorting_map";
import { MissionAddressesActions, selectMissionAddressesById } from "store/reducers/missions/addresses";

type PlaceListItemProps = {
    placeID: EntityId;
    onClick: (placeID: string) => void;
    onMouseEnter: (placeID: string) => void;
    onMouseLeave: (placeID: string) => void;
    handleCheckboxChange?: (addressData: SensitizationAddresses) => void;
}

function PlaceListItem(props: PlaceListItemProps) {
    const { placeID, onClick, onMouseEnter, onMouseLeave, handleCheckboxChange } = props;

    const { t } = useTranslation([Namespace.GLOSSARY]);
    const { missionID } = useParams();
    const partnerID = usePartnerID();

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const db = getFirestore();

    const selected = useAppSelector(state => state.batches.sortingMap.selectedAddressKey === placeID);
    // Fetch the address by ID or return false if it doesn't exist
    const isChecked = useAppSelector(state => Boolean(selectMissionAddressesById(state, placeID)));

    const sortingPoint = useAppSelector(state => selectSortingPointByAddressKey(state, placeID || ""))!;

    const address = formatAddress(sortingPoint.properties.address);

    const errorsCount = useAppSelector(state => selectSortingPointByAddressKey(state, placeID)?.properties.errorsCount ?? 0);
    const batchesCount = useAppSelector(state => selectSortingPointByAddressKey(state, placeID)?.properties.batchesCount ?? 0);

    const filterString = useAppSelector(state => state.batches.sortingMap.filterString);

    const filteredAddressComponents: [string] | [string, string, string] = useMemo(() => {
        if (!filterString) return [address];
        const startIndex = address.toLowerCase().indexOf(filterString.toLowerCase());
        return [address.slice(0, startIndex), address.slice(startIndex, startIndex + filterString.length), address.slice(startIndex + filterString.length)];
    }, [address, filterString]);

    let addressesRef: CollectionReference<DocumentData, DocumentData>;

    if (missionID && partnerID) { //only on mission map
        addressesRef = collection(db, DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS, missionID, DbCollection.SENSITIZATION_ADDRESSES);
    }

    const onCheckboxChange = () => {
        if (!sortingPoint) return;

        const addressKey = getBatchKey({
            hereID: sortingPoint.properties.hereID,
            address: sortingPoint.properties.address,
        });

        const addressData: SensitizationAddresses = {
            ID: doc(addressesRef).id,
            address: sortingPoint.properties.address,
            addressKey,
            errors: sortingPoint.properties.errors,
            errorsCount: sortingPoint.properties.errorsCount,
            lat: sortingPoint.geometry.coordinates[0],
            lng: sortingPoint.geometry.coordinates[1],
            batchesCount: batchesCount,
            visited: false,
        };

        if (handleCheckboxChange) {
            handleCheckboxChange(addressData);
        } else {
            dispatch(MissionAddressesActions.updateAddress(addressData));
        }
    };

    return (
        <ListItemButton
            onClick={() => onClick(placeID.toString())}
            onMouseEnter={() => onMouseEnter(placeID.toString())}
            onMouseLeave={() => onMouseLeave(placeID.toString())}
            sx={{
                bgcolor: selected ? "#ddeeff" : "#fff",
            }}
            autoFocus={selected}
        >
            {handleCheckboxChange && (
                <Checkbox
                    checked={isChecked}
                    onChange={onCheckboxChange}
                    sx={{ marginRight: theme.spacing(2), padding: 0, }}
                />
            )}
            <ListItemText
                primary={filteredAddressComponents.map((component, index) => (
                    <span
                        key={component}
                        {...(index === 1 && { // highlight matching string
                            style: {
                                backgroundColor: theme.palette.primary.light,
                            }
                        })}
                    >
                        {component}
                    </span>
                ))}
                secondary={`${t("errors_count", { ns: Namespace.GLOSSARY, count: errorsCount })} / ${t("images_count", { ns: Namespace.GLOSSARY, count: batchesCount })}`}
                primaryTypographyProps={{
                    variant: "body2",
                }}
                secondaryTypographyProps={{
                    variant: "button",
                }}
            />
        </ListItemButton>
    )
}

export default memo(PlaceListItem, (props1, props2) => _.isEqual(props1, props2));