import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MissionsData, MissionStatus } from "models/Missions";
import { UserData } from "models/User";
import { LoadableContext } from "store/store";

type SelectedMissionContext = LoadableContext & {
    data: MissionsData | null;
};

const initialState: SelectedMissionContext = {
    loading: false,
    error: null,
    data: null,
};

export const selectedMissionSlice = createSlice({
    name: 'mission',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.loading = true;
            state.error = null;
        },
        setSelected: (state, { payload: mission }: PayloadAction<MissionsData | null>) => {
            state.loading = false;
            state.data = mission;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        clearSelected: (state) => {
            state.data = null;
        },
        stopLoading: (state) => {
            state.loading = false;
            state.error = null;
        },
        updateAmbassadors: (state, { payload: ambassadors }: PayloadAction<UserData[]>) => {
            if (state.data) {
                state.data.assigned = ambassadors;
                state.data.status = MissionStatus.IN_PROGRESS;
            }
        },
    },
});

export const SelectedMissionActions = selectedMissionSlice.actions;

const SelectedMissionReducer = selectedMissionSlice.reducer;

export default SelectedMissionReducer;
