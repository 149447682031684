import { CollectionType } from "models/Collection";
import { SortingRulesActions } from "store/reducers/sorting_rules";
import { AppDispatch } from "store/store";

export const LOCAL_STORAGE_STREAM_KEY = "waste_stream";

const selectWasteStream = (wasteStream: CollectionType) => (dispatch: AppDispatch) => {
    dispatch(SortingRulesActions.selectWasteStream(wasteStream));

    //save preference in user's local storage
    localStorage.setItem(LOCAL_STORAGE_STREAM_KEY, wasteStream);
}

const WasteStreamController = {
    selectWasteStream,
}

export default WasteStreamController;