import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext, RootState } from "store/store";
import { MissionsData, ReportStats, ResponsivenessLevel } from "models/Missions";

const missionsAdapter = createEntityAdapter<MissionsData>({
    selectId: mission => mission.ID,
    sortComparer: (mission1, mission2) => {
        const name1 = mission1.name || "";
        const name2 = mission2.name || "";
        return name1.localeCompare(name2);
    }
});


interface MissionsState extends LoadableContext {
    aggregatedStats: ReportStats | null;
}

const initialState: MissionsState = {
    loading: true,
    error: null,
    aggregatedStats: null,
};

// Utility function to aggregate stats from a list of missions
const aggregateStatsFromMissions = (missions: MissionsData[]): ReportStats => {
    const aggregatedStats: ReportStats = {
        inhabitantsSpokenCount: 0,
        inhabitantsReceptiveCounts: {
            [ResponsivenessLevel.Responsive]: 0,
            [ResponsivenessLevel.Neutral]: 0,
            [ResponsivenessLevel.NotResponsive]: 0,
        },
        selectedTopicsCounts: {},
        communicationMethodsCounts: {},
        totalAddressesVisited: 0,
    };

    missions.forEach((mission) => {
        const reportStats = mission.reportStats;

        if (reportStats) {
            // Sum inhabitantsSpokenCount
            if (reportStats.inhabitantsSpokenCount && aggregatedStats.inhabitantsSpokenCount !== undefined) {
                aggregatedStats.inhabitantsSpokenCount += reportStats.inhabitantsSpokenCount;
            }

            // Sum totalAddressesVisited
            if (mission.addressesVisitedCount) {
                aggregatedStats.totalAddressesVisited += mission.addressesVisitedCount;
            }

            // Sum inhabitantsReceptiveCounts
            if (reportStats.inhabitantsReceptiveCounts) {
                Object.values(ResponsivenessLevel).forEach((mood) => {
                    const count = reportStats.inhabitantsReceptiveCounts![mood] || 0;
                    if (typeof count === 'number') {
                        aggregatedStats.inhabitantsReceptiveCounts![mood] += count;
                    }
                });
            }

            // Sum selectedTopicsCounts
            if (reportStats.selectedTopicsCounts) {
                Object.entries(reportStats.selectedTopicsCounts).forEach(
                    ([key, value]) => {
                        if (typeof value === 'number') {
                            if (!aggregatedStats.selectedTopicsCounts![key]) {
                                aggregatedStats.selectedTopicsCounts![key] = 0;
                            }
                            aggregatedStats.selectedTopicsCounts![key] += value;
                        }
                    }
                );
            }

            // Sum communicationMethodsCounts
            if (reportStats.communicationMethodsCounts) {
                Object.entries(reportStats.communicationMethodsCounts).forEach(
                    ([key, value]) => {
                        if (typeof value === 'number') {
                            if (!aggregatedStats.communicationMethodsCounts![key]) {
                                aggregatedStats.communicationMethodsCounts![key] = 0;
                            }
                            aggregatedStats.communicationMethodsCounts![key] += value;
                        }
                    }
                );
            }
        }
    });

    return aggregatedStats;
};


export const missionsSlice = createSlice({
    name: 'missions_list',
    initialState: missionsAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: missions }: PayloadAction<MissionsData[]>) => {
            missionsAdapter.setAll(state, missions);

            // Compute aggregated stats
            state.aggregatedStats = aggregateStatsFromMissions(missions);
            state.loading = false;
        },
        addItem: (state, { payload: mission }: PayloadAction<MissionsData>) => {
            state.loading = false;
            missionsAdapter.addOne(state, mission);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<MissionsData> }>) => {
            missionsAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const MissionsActions = missionsSlice.actions;

export const {
    selectAll: selectAllMissions,
    selectById: selectMissionById,
    selectIds: selectMissionsIds
} = missionsAdapter.getSelectors((state: RootState) => state.missions.missionsList);

const MissionsReducer = missionsSlice.reducer;

export default MissionsReducer;