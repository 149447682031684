import Route from "models/Route";
import { LoadableContext, RootState } from "store/store";
import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

type RouteContext = LoadableContext & {

}

const initialState: RouteContext = {
    loading: false,
    error: null,
};

const routesAdapter = createEntityAdapter<Route>({
    selectId: route => route.ID,
});

export const routesSlice = createSlice({
    name: 'routes',
    initialState: routesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: routes }: PayloadAction<Route[]>) => {
            state.loading = false;
            routesAdapter.setAll(state, routes);
        },
        addItem: (state, { payload: data }: PayloadAction<Route>) => {
            state.loading = false;
            routesAdapter.addOne(state, data);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<Route>}>) => {
            state.loading = false;
            routesAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        removeItem: (state, { payload: routeID }: PayloadAction<string>) => {
            state.loading = false;
            routesAdapter.removeOne(state, routeID);
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
    },
});

export const RoutesActions = routesSlice.actions;

export const {
    selectAll: selectAllRoutes,
    selectById: selectRouteById,
    selectIds: selectRoutesIds
} = routesAdapter.getSelectors((state: RootState) => state.routes.list)

const RoutesReducer = routesSlice.reducer;

export default RoutesReducer;