import urls, { SearchParam } from 'constants/urls';
import { Namespace } from 'locales/translations';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector, usePartnerID } from 'store/hooks';
import { stringifyUrl } from 'query-string';
import moment from 'moment';
import { API_TIMESTAMP_FORMAT } from 'constants/dates';
import { getAuth } from 'firebase/auth';
import { getResponseFilename } from 'controllers/actions';
import { downloadDataAsFile } from 'helpers/urls';
import { DownloadReportDialogContentProps } from './DownloadReportDialogContent';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import ActionButton from 'components/_include/Buttons/ActionButton';
import { ERRORS_BUTTONS_SPACING_WIDTH, ERRORS_BUTTONS_SPACING_TRANSLATION } from 'constants/theme';

function DownloadExcelButton({ parentPage }: DownloadReportDialogContentProps) {
    const { t } = useTranslation([Namespace.DIALOGS]);

    const partnerID = usePartnerID();

    const collection = useAppSelector(state => state.collections.selected.data);
    const truck = useAppSelector(state => state.trucks.selected.data);
    const route = useAppSelector(state => state.routes.selected.data);

    const [searchParams] = useSearchParams();
    const startDate = moment(searchParams.get("startDate")).startOf("day");
    const endDate = moment(searchParams.get("endDate")).endOf("day");
    const areaID = searchParams.get(SearchParam.AREA_ID);

    const [buttonLoading, setButtonLoading] = useState(false);

    const getReport = async (errorsOnly: boolean) => {
        setButtonLoading(true);

        // refresh Firebase token if needed
        const user = getAuth().currentUser;
        let token = await user?.getIdToken();

        // format query
        const url = parentPage === "stats" ? stringifyUrl({
            url: `${urls.REPORTS_API}/truck/excel`,
            query: {
                partnerID,
                truckID: truck?.ID,
                routeID: route?.ID,
                start: startDate.format(API_TIMESTAMP_FORMAT),
                end: endDate.format(API_TIMESTAMP_FORMAT),
                errorsOnly: errorsOnly,
                ...(areaID && { areaID }),
            },
        }) : stringifyUrl({
            url: `${urls.REPORTS_API}/collection/excel`,
            query: {
                partnerID,
                truckID: collection?.truckID,
                collectionID: collection?.ID,
                errorsOnly: errorsOnly,
            },
        });

        // query report Excel file
        const res = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/vnd.ms-excel',
            }
        })

        // save response data to Excel file
        const data = await res.blob();
        const defaultFilename = `rapport_${parentPage === "stats" ? truck?.identificationNumber : collection?.schedule.route.label}.xlsx`;
        const filename = getResponseFilename(res, defaultFilename);

        downloadDataAsFile(data, filename);

        setButtonLoading(false);
    };

    return (
        <Box position="absolute"  height='100%' width={theme => theme.spacing(ERRORS_BUTTONS_SPACING_WIDTH)} right={theme => theme.spacing(-ERRORS_BUTTONS_SPACING_TRANSLATION - ERRORS_BUTTONS_SPACING_WIDTH)} sx={{
           display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 3
        }}>
            <LoadingButton variant="outlined" onClick={() => getReport(false)} loading={buttonLoading} sx={{ py: 1.75 }} color="secondary" fullWidth>
                {t("all_bins_collected", { ns: Namespace.DIALOGS, context: parentPage })}
            </LoadingButton>
            <ActionButton color="secondary" onClick={() => getReport(true)} loading={buttonLoading} fullWidth>
                {t("only_sorting_mistakes", { ns: Namespace.DIALOGS, context: parentPage })}
            </ActionButton>
        </Box>
    );
}

export default DownloadExcelButton;