import { Box, Card } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'locales/translations';
import { Point } from '@nivo/line';

type ChartTooltipProps = {
    translationKey: string;
    slice: {
        points: Point[];
    };
};

const ChartTooltip: React.FC<ChartTooltipProps> = ({ slice, translationKey }) => {
    const { t } = useTranslation([Namespace.CHARTS, Namespace.DATES]);

    return (
        <Card
            sx={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
                borderRadius: 2,
            }}
        >
            <Box>
                {t("date_full_day", { ns: Namespace.DATES, date: moment(slice.points[0].data.x, "x") })}
            </Box>
            {slice.points.map((point) => {
                const realValue = point.data.yFormatted;
                return (
                    <Box
                        key={point.id}
                        sx={{
                            color: point.serieColor,
                            padding: "3px 0"
                        }}
                    >
                        <strong>{t(`${translationKey}.tooltip.${point.serieId}`, { ns: Namespace.CHARTS })}</strong>:   {realValue}
                    </Box>
                );
            })}
        </Card>
    );
};

export default ChartTooltip;
