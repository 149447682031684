import { Grid } from '@mui/material';
import AreaSelect from 'components/_include/Filters/AreaSelect';
import DatesRangeSelect from 'components/_include/Filters/DatesRangeSelect';
import PartnerSelect from 'components/_include/Filters/PartnerSelect';
import TruckSelect from 'components/_include/Filters/TruckSelect';
import { getDefaultFiltersValues, DangerLevel } from 'constants/stats';
import BatchesController from 'controllers/batches';
import { Timestamp } from 'firebase/firestore';
import { getDatesFromDateSelectType } from 'helpers/dates';
import moment from 'moment';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useIsAdmin, useSortingRules, usePartnerID } from 'store/hooks';
import { selectPartnersIds } from 'store/reducers/partners/list';
import ExportToGISButton from '../ExportToGISButton';
import ErrorsCountSelect, { ERRORS_COUNT_URL_PARAM } from './ErrorsCountSelect';
import ErrorsSelect from './ErrorsSelect';
import { TrashType } from 'constants/trash';
import { CollectionType } from 'models/Collection';
import { SearchParam } from 'constants/urls';
import RouteSelect from 'components/_include/Filters/RouteSelect';

/**
 * Props for SortingMapFilters component.
 * @typedef {Object} SortingMapFiltersProps
 * @property {boolean} [showMissionFilters=true] - Determines whether to show filters specific to mission page or not.
 */
type SortingMapFiltersProps = {
    showForMissions: boolean;
}

function SortingMapFilters({showForMissions: showMissionFilters}: SortingMapFiltersProps) {
    const isAdmin = useIsAdmin();
    const hasSeveralPartners = useAppSelector(state => selectPartnersIds(state).length > 1);
    const partnerID = usePartnerID();

    const { displayedErrors } = useSortingRules();

    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const loading = useAppSelector(state => state.batches.sortingMap.loading);

    const dispatch = useAppDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!partnerID || !wasteStream) return; // wait until partner and sorting rules are loaded

        // set default filters values
        let needUpdate = false;

        const { dates: defaultDateSelect, errors: defaultErrors, } = getDefaultFiltersValues();

        if (!searchParams.has(SearchParam.SELECTED_ERRORS)) {
            needUpdate = true;
            for (const error of defaultErrors) {
                searchParams.append(SearchParam.SELECTED_ERRORS, error);
            }
        }
        if (!searchParams.has("startDate") || !searchParams.has("endDate")) {
            needUpdate = true;
            const { start, end } = getDatesFromDateSelectType(defaultDateSelect);
            if (start) searchParams.set("startDate", start.format("YYYY-MM-DD"));
            if (end) searchParams.set("endDate", end.format("YYYY-MM-DD"));
        }

        if (needUpdate) {
            setSearchParams(searchParams);
        }
    }, [partnerID, wasteStream]);

    const startParam = searchParams.get("startDate");
    const endParam = searchParams.get("endDate");
    const truckIDParam = searchParams.get(SearchParam.TRUCK_ID);
    const routeIDParam = searchParams.get(SearchParam.ROUTE_ID);
    const areaID = searchParams.get(SearchParam.AREA_ID);
    const errors = searchParams.getAll(SearchParam.SELECTED_ERRORS)
        .filter(e => displayedErrors
            .includes(e as TrashType)) as TrashType[];
    const flattenedErrors = errors.join("");
    const hiddenParam = searchParams.get("hidden");
    const errorsCountParam = searchParams.get(ERRORS_COUNT_URL_PARAM);

    /**
     * Format the list of Firestore filters to apply on the "List batches" request
     */
    const getFilters = (collectionType: CollectionType, truckId?: string,) => {
        let filters: Parameters<typeof BatchesController.list>[1] = [
            { fieldPath: "display", opStr: "==", value: true },
            { fieldPath: "verified", opStr: "==", value: true },
            { fieldPath: "collectionType", opStr: "==", value: collectionType },
        ];

        // Include truckID filter only if showMissionFilters is false
        if (!showMissionFilters && truckId) {
            filters.push({ fieldPath: "truckID", opStr: "==", value: truckId });
        }

        if(routeIDParam){
            filters.push({ fieldPath: "routeID", opStr: "==", value: routeIDParam });
        }

        // dates filter
        filters.push({
            fieldPath: "timestamp",
            opStr: ">=",
            value: Timestamp.fromDate(moment(startParam).toDate()),
        });
        filters.push({
            fieldPath: "timestamp",
            opStr: "<=",
            value: Timestamp.fromDate(moment(endParam).endOf("day").toDate()),
        });

        // area filter
        if (areaID) {
            filters.push({
                fieldPath: "areasIDs",
                opStr: "array-contains",
                value: areaID,
            });
        }

        return filters;
    }

    /**
     * Load matching batches every time a parameter changes
     */
    useEffect(() => {
        if (partnerID && wasteStream && startParam && endParam) {
            const truckID = showMissionFilters ? undefined : truckIDParam;
            if (truckID === null) return; // force truck ID if not for missions

            const filters = getFilters(wasteStream, truckID);

            // danger level filter
            let matchingDangerLevel: [string, DangerLevel] | undefined;
            if (errorsCountParam) {
                matchingDangerLevel = Object.entries(DangerLevel).filter(([key, _]) => key === errorsCountParam)[0];
            }

            dispatch(BatchesController.listPartnerBatchesForMap(partnerID, filters, errors, "sorting", matchingDangerLevel?.[1]));
        }
    }, [truckIDParam, wasteStream, startParam, endParam, areaID, hiddenParam, flattenedErrors, partnerID, errorsCountParam, routeIDParam]);

    return (
        <Grid
            container
            alignContent="flex-start"
            spacing={1}
        >
            {!showMissionFilters && (isAdmin || hasSeveralPartners) && (
                <Grid item>
                    <PartnerSelect
                        parentLoading={loading}
                    />
                </Grid>
            )}
             {!showMissionFilters && (
                <Grid item>
                    <TruckSelect
                        parentLoading={loading}
                        needDefault
                    />
                </Grid>
            )}
            <Grid item>
                <AreaSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <RouteSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <DatesRangeSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <ErrorsSelect
                    parentLoading={loading}
                />
            </Grid>
            <Grid item>
                <ErrorsCountSelect
                    parentLoading={loading}
                />
            </Grid>
            {!showMissionFilters && (
            <Grid item>
                <ExportToGISButton
                    parentLoading={loading}
                />
            </Grid>
            )}
        </Grid>
    );
}

export default SortingMapFilters;