import { Grid } from '@mui/material';
import TruckSelect from 'components/_include/Filters/TruckSelect';
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import moment from 'moment';
import { useAppDispatch, useAppSelector, usePartnerID } from 'store/hooks';
import DatesRangeSelect from 'components/_include/Filters/DatesRangeSelect';
import { getDefaultFiltersValues } from 'constants/stats';
import { getDatesFromDateSelectType } from 'helpers/dates';
import DownloadReportButton from './DownloadReport/DownloadReportButton';
import AreaSelect from 'components/_include/Filters/AreaSelect';
import StatsMethods from 'controllers/stats';
import { SearchParam } from 'constants/urls';
import RouteSelect from 'components/_include/Filters/RouteSelect';

type FiltersProps = {

}

function StatisticsFilters(props: FiltersProps) {
    const loading = useAppSelector(state => state.trucks.list.loading || state.collections.list.loading);

    const dispatch = useAppDispatch();
    const partnerID = usePartnerID();

    const wasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const [searchParams, setSearchParams] = useSearchParams();
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    const areaID = searchParams.get(SearchParam.AREA_ID) ?? undefined;
    const truckID = searchParams.get(SearchParam.TRUCK_ID) ?? undefined;
    const routeID = searchParams.get(SearchParam.ROUTE_ID) ?? undefined;

    const datesSet = Boolean(startDate && endDate);

    useEffect(() => {
        // set default filters values
        let needUpdate = false;

        const { dates: defaultDateSelect, } = getDefaultFiltersValues();

        if (!searchParams.has("startDate") || !searchParams.has("endDate")) {
            needUpdate = true;
            const { start, end } = getDatesFromDateSelectType(defaultDateSelect);
            if (start) searchParams.set("startDate", start.format("YYYY-MM-DD"));
            if (end) searchParams.set("endDate", end.format("YYYY-MM-DD"));
        }

        if (needUpdate) {
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    // reload collections when filters change
    useEffect(() => {
        if (!startDate || !endDate || !wasteStream || !partnerID) return;
        // dates filter
        let start = moment(startDate).startOf("day");
        let end = moment(endDate).endOf("day");

        dispatch(StatsMethods.getStats(partnerID, wasteStream, start, end, truckID, areaID, routeID));
    }, [wasteStream, startDate, endDate, areaID, truckID, partnerID, routeID]);

    return (
        <Grid
            container
            display="flex"
            alignItems="center"
        >
            <Grid
                item
                xs
            >
                <Grid
                    container
                    alignItems="center"
                    display="flex"
                    columnSpacing={1}
                >
                    <Grid item>
                        <DatesRangeSelect
                            parentLoading={loading}
                        />
                    </Grid>
                    <Grid item>
                        <AreaSelect
                            needDefault={datesSet && Boolean(areaID) && !truckID} // set truck rather than area by default
                            parentLoading={loading}
                        />
                    </Grid>
                    <Grid item>
                        <TruckSelect
                            needDefault={datesSet && !areaID} // set truck rather than area by default
                            parentLoading={loading}
                        />
                    </Grid>
                    <Grid item>
                        <RouteSelect
                            parentLoading={loading}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                xs="auto"
            >
                <DownloadReportButton />
            </Grid>
        </Grid >
    );
}

export default StatisticsFilters;