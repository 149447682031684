import { useTheme } from "@mui/material";
import LineChart from "./LineChart";

export default function QualityEvolutionLineChart() {
    const theme = useTheme();

    return (
        <LineChart
            dataKey="quality"
            translationKey="quality_evolution"
            color={theme.palette.secondary.main}
            dataSelector={(state) => state.stats.data?.qualityPercentage}
            curve="monotoneX"
            enableArea
            yFormat=">-.2%"
            axisLeftFormat=">-.2p"
        />
    )
}