import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DialogIcon from 'components/_include/DialogIcon';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import RouteIcon from 'components/_include/Icons/RouteIcon';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import RoutesController from 'controllers/routes';
import { selectRouteById } from 'store/reducers/routes/list';

type DeleteRouteDialogProps = {
    routeID: string | null;
    onClose: () => void;
}

function DeleteRouteDialog(props: DeleteRouteDialogProps) {
    const { routeID, onClose } = props;

    const routeIdentifier = routeID || "";

    const route = useAppSelector(state => selectRouteById(state, routeIdentifier));
    const loading = useAppSelector(state => state.routes.list.loading);
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.FORMS]);

    const handleSaveRouteClick = () => {
        if (route) {
            dispatch(RoutesController.delete(route.partnerID, route.ID))
                .then((success) => {
                    if (success) { // route successfully added
                        onClose();
                    }
                });
        }
    }

    return (
        <Dialog
            open={route !== undefined}
            onClose={onClose}
        >
            <DialogIcon
                Icon={RouteIcon}
            />
            <DialogTitle align='center'>
                {t("onboarding.routes.delete_route", { ns: Namespace.FORMS })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t("onboarding.routes.delete_confirmation", { ns: Namespace.FORMS })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        color: "#4F4F4F",
                    }}
                    onClick={onClose}
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <LoadingButton
                    color="error"
                    sx={{
                    }}
                    onClick={handleSaveRouteClick}
                    loading={loading}
                >
                    <span>{t("confirm", { ns: Namespace.ACTIONS })}</span>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteRouteDialog;