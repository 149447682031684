import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, collection, doc, getDocs, getFirestore, updateDoc, writeBatch } from "firebase/firestore";
import { chunk, pickBy } from "lodash";
import { DbCollection } from "../constants/db";
import { MissionsActions } from "../store/reducers/missions/list";
import { SelectedMissionActions } from "../store/reducers/missions/selected";
import { AppDispatch } from "../store/store";
import { handleAPIError } from "./actions";
import { SensitizationAddresses } from "constants/types";
import { MissionAddressesActions } from "store/reducers/missions/addresses";

export const MAX_IN_QUERY = 10;

/**
 * Serialize a Mission's Address data from their database document
 */
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData>): SensitizationAddresses;
function fromDbDoc(dbDoc: DocumentSnapshot<DocumentData>): SensitizationAddresses;
function fromDbDoc(dbDoc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>) {
    const data = dbDoc.data() as SensitizationAddresses;
    const missionAddressData: SensitizationAddresses = {
        ...data,
        ID: dbDoc.id,
    };

    return missionAddressData;
}

/**
 * Retrieves all addresses for a specific mission.
 * @param {string} partnerID - The ID of the partner.
 * @param {string} missionID - The ID of the mission.
 * @returns {Promise<SensitizationAddresses[]>} The list of address documents.
 */
const getAddresses = (partnerID: string, missionID: string) => async (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.startLoading());

    try {
        const db = getFirestore();
        const addressesRef = collection(db, DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS, missionID, DbCollection.SENSITIZATION_ADDRESSES);
        const addressesSnapshot = await getDocs(addressesRef); // Get all addresses

        const missionAddresses = addressesSnapshot.docs
            .map(fromDbDoc)

        dispatch(MissionAddressesActions.setList(missionAddresses));
        return missionAddresses;
    } catch (e) {
        dispatch(handleAPIError(e, "fetching mission addresses", MissionsActions.setError));
        return null;
    }
};


/**
 * Action to create mission addresses in Firestore based on their existence.
 * 
 * @param partnerID - The ID of the partner.
 * @param missionID - The ID of the mission.
 * @param missionAddresses - An array of SensitizationAddresses objects to toggle.
 */
const updateMissionAddresses = (
    partnerID: string,
    missionID: string,
    missionAddresses: SensitizationAddresses[]
) => async (dispatch: AppDispatch) => {
    dispatch(SelectedMissionActions.startLoading());

    try {
        const db = getFirestore();

        // Reference to the 'sensitization_addresses' subcollection
        const addressesCollectionRef = collection(
            db,
            DbCollection.PARTNERS,
            partnerID,
            DbCollection.MISSIONS,
            missionID,
            DbCollection.SENSITIZATION_ADDRESSES
        );

        // Fetch all existing addresses
        const addressesSnapshot = await getDocs(addressesCollectionRef);

        // Map existing addresses to a Set of address keys for faster lookup
        const existingAddressKeys = new Set(addressesSnapshot.docs.map(doc => doc.data().addressKey));

        // Create a Set of new address keys
        const newAddressKeys = new Set(missionAddresses.map(address => address.addressKey));

        // Filter out addresses that need to be added (in new addresses but not in existing ones)
        const newAddresses = missionAddresses.filter(address => !existingAddressKeys.has(address.addressKey));

        // Filter out addresses that need to be removed (in existing addresses but not in the new ones)
        const addressesToRemove = addressesSnapshot.docs.filter(
            doc => !newAddressKeys.has(doc.data().addressKey)
        );

        // Chunk to split into batches of 500
        const newAddressBatches = chunk(newAddresses, 500);
        const removeAddressBatches = chunk(addressesToRemove, 500);

        const processBatch = async (newAddressesBatch: SensitizationAddresses[], removeBatch: DocumentData[]) => {
            const batch = writeBatch(db);

            // Add new addresses in the batch
            newAddressesBatch.forEach((address) => {
                const addressDocRef = doc(addressesCollectionRef); // Firestore-generated ID
                const addressData: SensitizationAddresses = {
                    ...address,
                    visited: false,
                };
                const sanitizedData = pickBy(addressData, (value) => value !== undefined);
                batch.set(addressDocRef, sanitizedData, { merge: true });
            });

            // Remove obsolete addresses in the batch
            removeBatch.forEach((address) => {
                const addressDocRef = doc(addressesCollectionRef, address.id);
                batch.delete(addressDocRef);
            });

            await batch.commit();
        };

        // Process all new address batches and remove address batches
        for (let i = 0; i < Math.max(newAddressBatches.length, removeAddressBatches.length); i++) {
            const newBatch = newAddressBatches[i] || [];
            const removeBatch = removeAddressBatches[i] || [];
            await processBatch(newBatch, removeBatch);
        }

        // Update the mission with the new counts
        const missionRef = doc(db, DbCollection.PARTNERS, partnerID, DbCollection.MISSIONS, missionID);
        await updateDoc(missionRef, {
            addressesCount: missionAddresses.length,
        });

    } catch (e) {
        dispatch(handleAPIError(e, "toggling mission addresses", MissionsActions.setError));
        return null;
    } finally {
        dispatch(SelectedMissionActions.stopLoading());
    }
};



const MissionAddressesMethods = {
    getAddresses,
    updateMissionAddresses,
};

export default MissionAddressesMethods;
