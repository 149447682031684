import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadableContext, RootState } from "store/store";
import { SensitizationAddresses } from "constants/types";

const missionAddressesAdapter = createEntityAdapter<SensitizationAddresses>({
    selectId: address => address.addressKey,
    sortComparer: (a, b) => {
        const keyA = a.addressKey || "";
        const keyB = b.addressKey || "";
        return keyA.localeCompare(keyB);
    },
});

const initialState: LoadableContext = {
    loading: false,
    error: null,
};


export const missionAddressesSlice = createSlice({
    name: 'mission_addresses',
    initialState: missionAddressesAdapter.getInitialState(initialState),
    reducers: {
        startLoadingList: (state) => {
            state.loading = true;
            state.error = null;
        },
        setList: (state, { payload: addresses }: PayloadAction<SensitizationAddresses[]>) => {
            state.loading = false;
            missionAddressesAdapter.setAll(state, addresses);
        },
        addItem: (state, { payload: address }: PayloadAction<SensitizationAddresses>) => {
            state.loading = false;
            missionAddressesAdapter.addOne(state, address);
        },
        updateItem: (state, { payload: { ID, data } }: PayloadAction<{ ID: string, data: Partial<SensitizationAddresses> }>) => {
            missionAddressesAdapter.updateOne(state, {
                id: ID,
                changes: data,
            });
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.loading = false;
            state.error = payload;
        },
        updateAddress: (state, { payload: address }: PayloadAction<SensitizationAddresses>) => {
            if (state.entities[address.addressKey]) {
                // Address exists, remove it
                missionAddressesAdapter.removeOne(state, address.addressKey);
            } else {
                // Address does not exist, add it with default 'visited' status
                missionAddressesAdapter.addOne(state, { ...address, visited: false });
            }
        },
    },
});

export const MissionAddressesActions = missionAddressesSlice.actions;

export const {
    selectAll: selectAllMissionAddresses,
    selectById: selectMissionAddressesById,
    selectIds: selectMissionAddressesIds
} = missionAddressesAdapter.getSelectors((state: RootState) => state.missions.missionAddresses);

const MissionAddressesReducer = missionAddressesSlice.reducer;

export default MissionAddressesReducer;