import { ButtonBase, Typography } from "@mui/material";
import { getWasteStreamFontColor } from "constants/theme";
import { Namespace } from "locales/translations";
import { CollectionType } from "models/Collection"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";

type LabelProps = {
    wasteStream: CollectionType;
    onClick: (wasteStream: CollectionType) => void;
}

export default function WasteStreamLabel({ wasteStream, onClick }: LabelProps) {
    const { t } = useTranslation([Namespace.GLOSSARY]);

    const selectedWasteStream = useAppSelector(state => state.sortingRules.selectedWasteStream);

    const isSelected = selectedWasteStream === wasteStream;

    return (
        <ButtonBase onClick={() => onClick(wasteStream)}>
            <Typography
                variant="h6"
                color={getWasteStreamFontColor(selectedWasteStream)}
                sx={{
                    opacity: isSelected ? 1 : 0.6,
                }}
            >
                {t(`waste_stream.${wasteStream}`, { ns: Namespace.GLOSSARY, context: "short" })}
            </Typography>
        </ButtonBase>
    )
}