import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import LocalShippingIcon from '@mui/icons-material/LocalShippingRounded';
import { useAppDispatch, useIsAdmin, usePartnerID } from "store/hooks";
import { selectAllTrucks } from "store/reducers/trucks/list";
import { SelectedTruckActions } from "store/reducers/trucks/selected";
import TrucksMethods from "controllers/trucks";
import { SearchParam } from "constants/urls";
import ReducerSelect from "./ReducerSelect";
import Truck from "models/Truck";

type TruckSelectProps = {
    parentLoading: boolean;
    needDefault?: boolean;
}

function TruckSelect(props: TruckSelectProps) {
    const { parentLoading, needDefault } = props;

    const userIsAdmin = useIsAdmin();

    const partnerID = usePartnerID();
    const dispatch = useAppDispatch();

    const { t } = useTranslation([Namespace.FORMS]);

    /** List trucks upon first load and when partner changes */
    const listTrucks = () => {
        if (partnerID) {
            dispatch(TrucksMethods.list(partnerID, []));
        }
    };

    /** Show sensor number to admins. */
    const formatTruckLabel = (truck: Truck) => truck.displayID + (userIsAdmin ? " - " + truck.sensorNumber : "");

    return (
        <ReducerSelect
            id="truck-select-menu"
            parentLoading={parentLoading}
            loadingSelector={state => state.trucks.list.loading}
            optionsSelector={selectAllTrucks}
            valueSelector={state => state.trucks.selected.data}
            selectValue={SelectedTruckActions.selectTruck}
            formatOptionLabel={formatTruckLabel}
            searchParam={SearchParam.TRUCK_ID}
            onInit={{
                effect: listTrucks,
                deps: [partnerID],
            }}
            startIcon={<LocalShippingIcon />}
            needDefault={needDefault}
            selectAllLabel={t("select.all_trucks", { ns: Namespace.FORMS })}
            />
    );
}

export default TruckSelect;