import { PayloadAction, createSlice, } from "@reduxjs/toolkit";
import { TrashType } from "constants/trash";

type WasteTypeContext = {
    count: number;
    open: boolean;
}

export type WasteInputValues = {
    [type in TrashType]: WasteTypeContext;
};

const initialState: WasteInputValues = Object.values(TrashType).reduce((acc, trashType) => {
    acc[trashType] = {
        count: 0,
        open: false,
    };
    return acc;
}, {} as WasteInputValues);

export const wastesInputsSlice = createSlice({
    name: 'wastes_inputs',
    initialState: initialState,
    reducers: {
        setOne: (state, { payload: { trashType, values } }: PayloadAction<{ trashType: TrashType, values: WasteTypeContext }>) => {
            state[trashType] = values;
        },
        setAll: (state, { payload: inputs }: PayloadAction<WasteInputValues>) => {
            Object.entries(inputs).forEach(([trashType, values]) => {
                state[trashType as TrashType] = values;
            });
        },
        /**
         * Add one instance of a type of waste.
         */
        incrementOne: (state, { payload: { trashType } }: PayloadAction<{ trashType: TrashType }>) => {
            state[trashType] = {
                ...state[trashType],
                count: state[trashType].count + 1,
            };
        },
        /**
         * Remove one instance of a type of waste.
         */
        decrementOne: (state, { payload: { trashType } }: PayloadAction<{ trashType: TrashType }>) => {
            const newCount = state[trashType].count - 1;
            state[trashType] = {
                ...state[trashType],
                count: newCount,
            };
        },
        /**
         * Exchange the type of waste of an instance.
         * It decrements the count for the old class,
         * and increments it for the new class.
         * Also opens the drawer for the new class.
         */
        exchangeOne: (state, { payload: { oldType, newType } }: PayloadAction<{ oldType: TrashType, newType: TrashType }>) => {
            state[newType] = {
                ...state[newType],
                count: state[newType].count + 1,
                open: newType !== oldType || newType !== TrashType.RECYCLE_WASTE, // don't open recyclable waste by default
            };
            
            const newCount = state[oldType].count - 1;
            
            state[oldType] = {
                ...state[oldType],
                count: newCount,
            };
        },
        /**
         * Open or close the drawer for a specific waste class
         */
        toggleDrawer: (state, { payload: { trashType, open } }: PayloadAction<{ trashType: TrashType, open: boolean }>) => {
            state[trashType] = {
                ...state[trashType],
                open: open,
            }
        },
    },
});

export const WastesInputsActions = wastesInputsSlice.actions;

const WastesInputsReducer = wastesInputsSlice.reducer;

export default WastesInputsReducer;