import { DialogContent, Typography, Grid, Divider } from "@mui/material";
import { Namespace } from "locales/translations";
import DownloadExcelButton from "./DownloadExcelButton";
import DownloadPDFButton from "./DownloadPDFButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ReportFormatButton from "./ReportFormatButton";
import urls from "constants/urls";
import theme, { ERRORS_BUTTONS_SPACING_TRANSLATION, ERRORS_BUTTONS_SPACING_WIDTH } from "constants/theme";

export type DownloadReportDialogContentProps = {
    parentPage: "stats" | "collection";
}

export default function DownloadReportDialogContent({ parentPage }: DownloadReportDialogContentProps) {
    const { t } = useTranslation([Namespace.DIALOGS]);
    const [showButtons, setShowButtons] = useState(false);

    // Toggle showButtons to slide the buttons into view
    const handleToggleButtons = () => {
        setShowButtons(prev => !prev);
    };

    return (
        <DialogContent sx={{ overflow: 'hidden' }}>
            <Typography
                variant="subtitle1"
                color={theme => theme.palette.grey[500]}
            >
                {t("download_report.instruction", { ns: Namespace.DIALOGS })}
            </Typography>
            <Grid container wrap="nowrap" sx={{
                minWidth: '500px',
                position: 'relative',
                transform: theme => `translateX(${showButtons ? theme.spacing(-ERRORS_BUTTONS_SPACING_TRANSLATION - ERRORS_BUTTONS_SPACING_WIDTH - 5) : 0})`,
                transition: 'transform 0.5s ease-in-out',
                mt: 4
            }}>
                {/* PDF Button */}
                <Grid item xs={8}>
                    <DownloadPDFButton parentPage={parentPage} />
                </Grid>
                <Grid item xs={2} >
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            mx: "50%",
                            height: "100%",
                        }}
                    />
                </Grid>

                {/* Excel Button with click event */}
                <Grid item xs={5} mr={theme => theme.spacing(12)}>
                    <ReportFormatButton
                        imgSource={`${urls.ICONS_FOLDER}/excel_icon.png`}
                        label={t("download_report.excel", { ns: Namespace.DIALOGS, context: parentPage })}
                        onClick={handleToggleButtons}
                    />
                </Grid>
                <DownloadExcelButton parentPage={parentPage} />
            </Grid>
        </DialogContent>
    );
}
