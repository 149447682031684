import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Box } from "@mui/material";
import DropdownMenu, { Option } from "components/_include/DropdownMenu/DropdownMenu";
import CollectionsController from "controllers/collections";
import { getWasteStreamIcon } from "helpers/trash";
import { Namespace } from "locales/translations";
import { CollectionType, DISPLAYED_COLLECTION_TYPES } from "models/Collection";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store/hooks";

type DialogProps = {
    open: boolean;
    onClose: () => void;
}

export default function ChangeCollectionTypeDialog({ open, onClose, }: DialogProps) {

    const { t } = useTranslation([Namespace.ACTIONS, Namespace.GLOSSARY, Namespace.DIALOGS]);

    const collection = useAppSelector(state => state.collections.selected.data)!;

    const dispatch = useAppDispatch();

    const [selectedWasteStream, setSelectedWasteStream] = useState(collection.type);

    const COLLECTION_TYPE_SELECT_OPTIONS = useMemo(() => [...DISPLAYED_COLLECTION_TYPES, CollectionType.HIDDEN]
        .map(type => {
            const Icon = getWasteStreamIcon(type);
            const option: Option = {
                label: t(`waste_stream.${type}`, { ns: Namespace.GLOSSARY }),
                value: type,
                startAdornment: Icon && <Icon />
            };

            return option;
        }),
        [t]
    );


    const changeCollectionType = useCallback(() => {
        dispatch(CollectionsController.changeCollectionType(collection, selectedWasteStream));
        onClose();
    }, [collection, selectedWasteStream]);

    const SelectedIcon = getWasteStreamIcon(selectedWasteStream);

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                {t(`change_collection_type.title`, { ns: Namespace.DIALOGS })}
            </DialogTitle>

            <DialogContent>
                <DialogContentText color="rgba(0,0,0,.5)" fontStyle="italic">
                    {t(`change_collection_type.content`, { ns: Namespace.DIALOGS, type: collection.type })}
                </DialogContentText>

                <Box
                    mt={2}
                    display="flex"
                    flexDirection="column"
                    gap={3}
                    >
                    <Typography>
                        {t(`change_collection_type.instruction`, { ns: Namespace.DIALOGS })}
                    </Typography>

                    <Box alignSelf="center" minWidth="180px">
                        <DropdownMenu
                            id="change-collection-type-select"
                            title={t(`waste_stream.${selectedWasteStream}`, { ns: Namespace.GLOSSARY, context: "full" })}
                            values={COLLECTION_TYPE_SELECT_OPTIONS}
                            defaultValue={selectedWasteStream ?? CollectionType.SORTABLE_WASTE}
                            startIcon={SelectedIcon && <SelectedIcon />}
                            onChange={(v) => { setSelectedWasteStream(v as CollectionType) }}
                            fullWidth
                        />
                    </Box>
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={onClose}
                    color="secondary"
                >
                    {t("cancel", { ns: Namespace.ACTIONS })}
                </Button>
                <Button
                    onClick={() => changeCollectionType()}
                    color="error"
                    disabled={collection.type === selectedWasteStream}
                >
                    {t("confirm", { ns: Namespace.ACTIONS })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}