import React from 'react';
import { Chip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { clearSelectedErrorType } from 'store/reducers/errors/selected';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';

type SelectedErrorTypeChipProps = {
  style?: React.CSSProperties;
  sx?: object;
};

const SelectedErrorTypeChip: React.FC<SelectedErrorTypeChipProps> = ({ style, sx }) => {
  const { t } = useTranslation([Namespace.WASTES]);
  const selectedErrorType = useAppSelector((state) => state.selectedErrorType.errorType);
  const dispatch = useAppDispatch();

  if (!selectedErrorType) return null;

  const handleDelete = () => {
    dispatch(clearSelectedErrorType());
  };

  return (
    <Chip
      label={t(selectedErrorType, { ns: Namespace.WASTES })}
      onDelete={handleDelete}
      variant="filled"
      style={style}
      sx={{
        boxShadow: 4,
        backgroundColor: 'whitesmoke',
        ...sx,
      }}
    />
  );
};

export default React.memo(SelectedErrorTypeChip);
