import { mergeTrashCounts } from "helpers/trash";
import { max } from "lodash";
import { useMemo } from "react";
import { useAppSelector, useSortingRules } from "store/hooks";
import { ErrorPoint } from "../../../_include/Charts/AddressesWithMostErrors/AddressesWithMostErrors";
import { TrashType } from "constants/trash";
import MostFrequentErrorsBarsChart, { BAR_COLORS, ERROR_CATEGORY, TEXT_COLORS } from "components/_include/Charts/MostFrequentErrors/MostFrequentErrorsBarsChart";
import { useSpacing } from "constants/theme";
import { Box, Grid, Typography } from "@mui/material";
import ChartTitle from "components/Statistics/ChartTitle";
import { Namespace } from "locales/translations";
import { useTranslation } from "react-i18next";
import SelectedErrorTypeChip from "../Actions/SelectedErrorChip";

const PADDING_X = 3;
const CHART_HEIGHT = 36;

export default function MostFrequentErrorsChart() {
    const { t } = useTranslation([Namespace.CHARTS, Namespace.WASTES]);

    const collectionResults = useAppSelector(state => state.collections.selected.data?.results);

    const { mergingMapping, displayedErrors } = useSortingRules();

    const data: ErrorPoint[] = useMemo(() => {
        let errorsTypes: ErrorPoint[] = [];
        if (!collectionResults) return errorsTypes; // results not loaded yet

        const mergedResults = mergeTrashCounts(mergingMapping, collectionResults);
        const maxErrorCount = max(
            Object.entries(mergedResults)
                .filter(([trashType, _]) => displayedErrors.includes(trashType as TrashType))
                .map(([_, count]) => count)
        )!;

        for (const errorType of displayedErrors) {
            const count = mergedResults[errorType] ?? 0;

            const portion = count / maxErrorCount;
            let category: ERROR_CATEGORY;
            if (portion > 0.8) category = ERROR_CATEGORY.WORST;
            else if (portion > 0.5) category = ERROR_CATEGORY.BAD;
            else if (portion > 0.1) category = ERROR_CATEGORY.MEDIUM;
            else category = ERROR_CATEGORY.GOOD;

            errorsTypes.push({
                trashType: errorType,
                count,
                color: BAR_COLORS[category],
                textColor: TEXT_COLORS[category],
            });
        }

        return errorsTypes.sort((p1, p2) => p1.count - p2.count); // sort by items count desc
    }, [collectionResults, displayedErrors, mergingMapping]);

    const heightPixels = useSpacing(36);
    const chartBarsHeight = useSpacing(data.length * 4);

    return (
        <Box
            justifyContent="center"
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <ChartTitle sx={{ px: PADDING_X, }}>
                    {t("most_frequent_errors.title", { ns: Namespace.CHARTS })}
                    <br />
                    <Typography component="span" variant="caption">
                        {t("click_to_filter_map", { ns: Namespace.CHARTS })}
                    </Typography>
                </ChartTitle>
                <SelectedErrorTypeChip
                    sx={{
                        mr: PADDING_X,
                        maxWidth: 200,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        backgroundColor: 'whitesmoke'
                    }}
                />
            </Grid>
            <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                alignItems="stretch"
                minHeight={CHART_HEIGHT}
            >
                <Grid container>
                    <Grid
                        item
                        height={Math.max(chartBarsHeight, heightPixels)}
                        sm={12}
                        px={PADDING_X}
                        overflow="scroll"
                    >
                        <MostFrequentErrorsBarsChart data={data} interactive />
                    </Grid>
                </Grid>
            </Box>
        </Box >
    )
}