import { Box, Typography } from '@mui/material';
import { ResponsiveRadar } from '@nivo/radar';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';

interface SelectedTopics {
    [key: string]: number;
}
interface TopicsSectionProps {
    selectedTopics: SelectedTopics;
    maxValue?: number | 'auto';
}

const TopicsSection = ({ selectedTopics, maxValue }: TopicsSectionProps) => {
    const { t } = useTranslation([Namespace.MISSIONS]);

    const countKey = t('discussed_topics.count', { ns: Namespace.MISSIONS });

    const data = Object.entries(selectedTopics).map(([key, value]) => ({
        topic: t(`discussed_topics.${key}`, { ns: Namespace.MISSIONS }),
        [countKey]: value,
    }));

    return (
        <Box display="flex" flexDirection="column" minWidth="380px">
            <Typography variant="h5">{t(`discussed_topics.title`, { ns: Namespace.MISSIONS })}</Typography>
            <Box width="100%" height="210px" justifyContent="center" alignSelf="center"  >
                <ResponsiveRadar
                    data={data}
                    keys={[countKey]}
                    indexBy="topic"
                    maxValue={maxValue}
                    margin={{ top: 20, right: 45, bottom: 20, left: 0 }}
                    curve="linearClosed"
                    borderWidth={2}
                    borderColor="#5687F2"
                    gridLevels={5}
                    gridShape="linear"
                    gridLabelOffset={10}
                    enableDots={false}
                    colors="#5687F2"
                    fillOpacity={0.25}
                    blendMode="multiply"
                    animate={true}
                    motionConfig="wobbly"
                    theme={{
                        axis: {
                            ticks: {
                                text: {
                                    fontSize: 12,
                                    fontWeight: 500,
                                    fontFamily: 'CircularStd'
                                },
                            },
                        },
                    }}
                />
            </Box>
        </Box>
    );
};

export default TopicsSection;
