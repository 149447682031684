import { AppDispatch } from "store/store";
import { StatsActions } from "store/reducers/stats";
import { showError } from "store/reducers/snacks";
import moment from "moment";
import { fetchAPI } from "./actions";
import { stringifyUrl } from "query-string";
import urls from "constants/urls";
import { API_TIMESTAMP_FORMAT } from "constants/dates";
import Stats from "models/Stats";
import { CollectionType } from "models/Collection";

const getStats = (partnerID: string, type: CollectionType, start: moment.Moment, end: moment.Moment, truckID?: string, areaID?: string, routeID?: string) => async (dispatch: AppDispatch) => {
    if (!truckID && !areaID) return; // only load if at least truckID or areaID is set
    
    dispatch(StatsActions.startLoading());

    const query = stringifyUrl({
        url: `${urls.API}/partner/${partnerID}/stats`,
        query: {
            start: start.format(API_TIMESTAMP_FORMAT),
            end: end.format(API_TIMESTAMP_FORMAT),
            areaID,
            truckID,
            type,
            routeID,
        },
    });

    try {
        const stats: Stats = await fetchAPI(query);

        dispatch(StatsActions.setData(stats));

        return stats;
    }
    catch (e) {
        const error = e as Error;
        console.error("Failed to load stats", error);
        dispatch(showError(error.message));
        dispatch(StatsActions.setError(error.message));
        return null;
    }
};

const StatsMethods = {
    getStats,
};

export default StatsMethods;