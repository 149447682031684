
import { Checkbox, ListItem, ListItemText } from '@mui/material';
import { formatAddress } from 'helpers/geo';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { MissionAddressesActions, selectMissionAddressesById } from 'store/reducers/missions/addresses';

type SelectedAddressListItemProps = {
    addressId: string;
};

const SelectedAddressListItem: React.FC<SelectedAddressListItemProps> = ({ addressId }) => {
    const dispatch = useAppDispatch();
    const missionAddress = useAppSelector(state => selectMissionAddressesById(state, addressId))!;

    const handleCheckboxChange = useCallback(() => {
        dispatch(MissionAddressesActions.updateAddress(missionAddress));
    }, [dispatch, missionAddress]);

    return (
        <ListItem sx={{ padding: 0 }}>
            <Checkbox
                disableRipple={true}
                sx={{ paddingLeft: 0, paddingRight: 1 }}
                checked={!!missionAddress}
                onChange={handleCheckboxChange}
            />
            <ListItemText primary={formatAddress(missionAddress.address)} />
        </ListItem>
    );
};

export default SelectedAddressListItem;
