import { Box, Container, Grid, Paper } from "@mui/material";
import StatisticsFilters from "./StatisticsFilters";
import { styled } from "@mui/system";
import PageTitle from "components/_include/PageTitle";
import MostFrequentErrorsChart from "./MostFrequentErrorsChart";
import WorstAddressesSection from "./WorstAddressesSection/WorstAddressesSection";
import QualityEvolutionLineChart from "./QualityEvolutionLineChart";
import ErrorsCountEvolutionLineChart from "./ErrorsCountEvolutionLineChart";

type StatisticsProps = {

}

const CHART_HEIGHT = 40;

const ChartPaper = styled(Paper)(({ theme }) => ({
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));

function StatisticsPage(props: StatisticsProps) {

    return (
        <Container
            sx={{
                // bgcolor: "#EFEFEF",
            }}
        >
            <PageTitle />
            <Box>
                <StatisticsFilters />
                <Grid
                    container
                    my={4}
                    spacing={4}
                >
                    <Grid
                        item
                        md={12}
                        lg={12}
                    >
                        <ChartPaper>
                            <MostFrequentErrorsChart height={CHART_HEIGHT} />
                        </ChartPaper>
                    </Grid>

                    <WorstAddressesSection
                        chartsHeight={CHART_HEIGHT}
                        PaperComponent={({ children }) => (
                            <ChartPaper>
                                {children}
                            </ChartPaper>
                        )}
                    />

                    <Grid
                        item
                        md={12}
                        lg={6}
                    >
                        <ChartPaper>
                            <ErrorsCountEvolutionLineChart />
                        </ChartPaper>
                    </Grid>
                    <Grid
                        item
                        md={12}
                        lg={6}
                    >
                        <ChartPaper>
                            <QualityEvolutionLineChart />
                        </ChartPaper>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    );
};

export default StatisticsPage;
