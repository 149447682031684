import { Grid } from "@mui/material";
import { useAppSelector } from "store/hooks";
import ReportNotAvailableCard from "./ReportNotAvailableCard";
import ReportStatsCard from "./ReportStatsCard";

function MissionsReportStats() {
    const aggregatedStats = useAppSelector(state => state.missions.missionsList.aggregatedStats);

    return (
        <Grid container paddingTop={4}>
            {aggregatedStats ? (
                <ReportStatsCard />
            ) : (
                <ReportNotAvailableCard />
            )
            }
        </Grid>
    );
}

export default MissionsReportStats;
