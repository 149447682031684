import { Box, List, Typography } from '@mui/material';
import SectionLoader from 'components/_include/SectionLoader';
import { Namespace } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import SelectedAddressListItem from './SelectedAddressListItem';
import { selectMissionAddressesIds } from 'store/reducers/missions/addresses';

const SelectedAddressesList: React.FC = () => {
    const { t } = useTranslation([Namespace.MISSIONS]);

    const missionAddressesIds = useAppSelector(selectMissionAddressesIds);
    const loading = useAppSelector(state => state.missions.missionAddresses.loading);

    let content;

    if (loading) {
        content = <SectionLoader />;
    } else if (missionAddressesIds.length === 0) {
        content = (
            <Box flexGrow={1} display="flex" flexDirection="column">
                <Typography fontStyle="italic" color="rgba(0,0,0,0.36)">
                    {t('no_address_found', { ns: Namespace.MISSIONS })}
                </Typography>
            </Box>
        );
    } else {
        content = (
            <>
                <Typography variant="h6" gutterBottom>
                    {t('selected_addresses', { ns: Namespace.MISSIONS, count: missionAddressesIds.length })}
                </Typography>
                <Box sx={{ overflowY: 'auto', maxHeight: '45vh' }}>
                    <List>
                        {missionAddressesIds.map(addressId => (
                            <SelectedAddressListItem key={addressId} addressId={addressId.toString()} />
                        ))}
                    </List>
                </Box>
            </>
        );
    }

    return (
        <Box
            flexGrow={1}
            maxHeight="50vh"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
        >
            {content}
        </Box>
    );
};


export default SelectedAddressesList;
