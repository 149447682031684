import { Timestamp } from "firebase/firestore";
import { UserData } from "./User";

export enum MissionStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELLED = 'CANCELLED'
}


export enum ResponsivenessLevel {
    Responsive = 'responsive',
    Neutral = 'neutral',
    NotResponsive = 'not_responsive',
}

export type MoodStats = { [level in ResponsivenessLevel]: number };

export type ReportStats = {
    totalAddressesVisited: number;
    inhabitantsSpokenCount?: number;
    inhabitantsReceptiveCounts?: {
        [key in ResponsivenessLevel]: number;
    };
    selectedTopicsCounts?: {
        [key: string]: number;
    };
    communicationMethodsCounts?: {
        [key: string]: number;
    };
};

export type MissionsData = {
    ID: string;
    name: string;
    deadline: string | undefined;
    createdAt?: string;
    assigned?: UserData[];
    mapImageURL?: string;
    status?: MissionStatus;
    addressesCount: number;
    addressesVisitedCount: number;
    reportStats?: ReportStats;
}

export type MissionDbData = {
    ID: string;
    name: string;
    deadline: Timestamp | undefined;
    createdAt?: Timestamp;
    assigned: UserData[];
    mapImageURL?: string;
    status?: MissionStatus;
    addressesCount: number;
    addressesVisitedCount: number;
    reportStats?: ReportStats;
}