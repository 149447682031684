// src/store/selectedErrorTypeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectedErrorTypeState {
    errorType: string | null;
}

const initialState: SelectedErrorTypeState = {
    errorType: null,
};

const selectedErrorTypeSlice = createSlice({
    name: 'selectedErrorType',
    initialState,
    reducers: {
        setSelectedErrorType(state, action: PayloadAction<string>) {
            state.errorType = action.payload;
        },
        clearSelectedErrorType(state) {
            state.errorType = null;
        },
    },
});

export const { setSelectedErrorType, clearSelectedErrorType } = selectedErrorTypeSlice.actions;

const SelectedErrorTypeReducer = selectedErrorTypeSlice.reducer;

export default SelectedErrorTypeReducer;
