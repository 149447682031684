import { useTheme } from "@mui/material";
import LineChart from "./LineChart";

export default function ErrorsCountEvolutionLineChart() {

    const theme = useTheme();

    return (
        <LineChart
            dataKey="count"
            translationKey="errors_count_evolution"
            color={theme.palette.primary.main}
            dataSelector={(state) => state.stats.data?.errorsCounts}
            yFormat=" >-.2r"
            axisLeftFormat=" >-.2r"
            />
    )
}