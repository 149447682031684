import { FlagSharp } from '@mui/icons-material';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import EditIcon from '@mui/icons-material/Edit';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeopleRounded';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import MapIcon from '@mui/icons-material/Map';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PreviewIcon from '@mui/icons-material/PreviewRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import TimelineIcon from '@mui/icons-material/Timeline';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ContactsPage from "components/Contacts/ContactsPage";
import DemoBatchPage from 'components/DemoBatch/DemoBatchPage';
import SingleImagePage from 'components/SingleImage/SingleImagePage';
import ImagesToAnnotatePage from "components/ImagesToAnnotate/ImagesToAnnotatePage";
import LoginPage from "components/Login/LoginPage";
import ManageAreasPage from 'components/ManageAreas/ManageAreasPage';
import ManageBatchesPage from "components/ManageBatches/ManageBatchesPage";
import ManageCollectionsPage from "components/ManageCollections/ManageCollectionsPage";
import SortingMapPage from "components/Map/SortingMapPage";
import MissionDetailPage from 'components/Missions/MissionDetail/MissionDetailPage';
import MissionsPage from 'components/Missions/MissionPage';
import LoggedInLayout from "components/Navigation/LoggedInLayout";
import LoggedOutLayout from 'components/Navigation/LoggedOutPage';
import PartnersPage from 'components/PartnersPage/PartnersPage';
import SettingsPage from 'components/SettingsPage/SettingsPage';
import StatisticsPage from "components/Statistics/StatisticsPage";
import { Navigate, RouteObject } from "react-router-dom";
import NoPartnerPage from "components/NoPartner/NoPartnerPage";

export type AppIcon = OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
};

type MainPage = Omit<RouteObject, "path" | "children" | "index"> & {
    path?: Section | "*";
    label?: string; // drawer label
    Icon?: AppIcon; // drawer icon, set to undefined to hide
    children?: (MainPage | RouteObject)[];
    index?: false;
}

type DrawerItemBase = Omit<RouteObject, "path" | "children"> & {
    Icon: AppIcon;
    children?: RouteObject[];
}

type DrawerItemWithLabel = DrawerItemBase & {
    label: string;
    path?: never;
}

type DrawerItemWithPath = DrawerItemBase & {
    path: string;
    label?: never;
}

type DrawerItem = DrawerItemWithLabel | DrawerItemWithPath | DrawerItemWithLabel & DrawerItemWithPath;

export const getRoutes = (isAdmin: boolean, canWrite: boolean, canAccessSettings: boolean, hasPartners?: boolean,) => {
    let pages = getAllRoutes(); // all routes by default

    if (!isAdmin && (!canWrite || !canAccessSettings)) {
        // remove settings route for users whose partner cannot access settings or don't have write permissions
        pages[0].children = pages[0].children!.filter(r => r.path !== SETTINGS_ROUTE.path);
    }

    if (!isAdmin) {
        // remove admin routes
        pages[0].children = pages[0].children!.filter(r => !ADMIN_ONLY_ROUTES.find(r2 => r2.path === r.path));
    }

    if (hasPartners === false) { // no partner associated
        pages[0].children = [
            { // default route shows message explain how to get associated with partner
                path: "*",
                element: <Navigate to={Section.NO_PARTNER} replace />,
            },
            ...NO_PARTNER_ROUTES,
        ];
    }
    else if (hasPartners === true) { // normal user
        pages[0].children = [
            // remove "no partner" pages
            ...pages[0].children!.filter(r => !NO_PARTNER_ROUTES.find(r2 => r2.path === r.path)),
            { // default route redirects to stats
                path: "*",
                element: <Navigate to={Section.COLLECTIONS} replace />,
            },
        ];
    }
    
    // else: user not loaded yet

    return pages;
}

export function getDrawer(isAdmin: boolean, canWrite: boolean, canAccessSettings: boolean, hasPartners?: boolean): DrawerItem[] {
    const routes = getRoutes(isAdmin, canWrite, canAccessSettings, hasPartners);
    const items = routes[0].children as MainPage[]; // only logged in pages
    return items.filter(item => item.Icon !== undefined) as DrawerItem[]; // remove routes with an icon (not app section)
}

/** Different sections of the app. */
export enum Section {
    // logged in
    STATS = "statistics",
    MAP = "map",
    COLLECTIONS = "collections",
    SETTINGS = "settings",
    CONTACTS = "contacts",
    AREAS = "areas",
    SINGLE_IMAGE = "single-image",
    //onboarding
    ONBOARDING = "onboarding",
    // admin
    BATCHES = "batches",
    DEMO = "demo",
    TO_ANNOTATE = "to-annotate",
    PARTNERS = "partners",
    MISSIONS = "missions",
    // no partner associated
    NO_PARTNER = "no-partner",
    // logged out
    LOGIN = "login",
}

/** Pages displayed to new users during onboarding process. */
// const ONBOARDING_ONLY_ROUTES: MainPage[] = [
//     {
//         label: "welcome",
//         path: Section.ONBOARDING,
//         element: <OnboardingPage />,
//         Icon: EmojiPeopleIcon,
//     },
// ];

/** Pages displayed to all logged in users. */
const LOGGED_IN_ONLY_ROUTES: MainPage[] = [
    {
        path: Section.COLLECTIONS,
        element: <ManageCollectionsPage />,
        Icon: DepartureBoardIcon,
    },
    {
        path: Section.STATS,
        element: <StatisticsPage />,
        Icon: TimelineIcon,
    },
    {
        path: Section.MAP,
        element: <SortingMapPage showForMissions={false} />,
        Icon: MapIcon,
    },
    {
        path: Section.AREAS,
        element: <ManageAreasPage />,
        Icon: ZoomInMapIcon,
    },
    {
        path: Section.MISSIONS,
        Icon: FlagSharp,
        children: [
            {
                index: true,
                element: <MissionsPage />
            },
            {
                path: ":missionID/edit",
                element: <MissionDetailPage />
            }
        ]
    },
    {
        path: Section.CONTACTS,
        element: <ContactsPage />,
        Icon: PeopleIcon,
    },
];

/** Page to visualize a single batch image with its AI results. Only accessible through exact link. */
const SINGLE_IMAGE_ROUTE: MainPage = {
    path: Section.SINGLE_IMAGE,
    element: <SingleImagePage />,
};

/** Pages displayed to logged in users with write permissions. */
const SETTINGS_ROUTE: MainPage = {
    path: Section.SETTINGS,
    element: <SettingsPage />,
    Icon: SettingsIcon,
};

/** Pages displayed to Ficha admins only. */
const ADMIN_ONLY_ROUTES: MainPage[] = [
    {
        path: Section.BATCHES,
        element: <ManageBatchesPage />,
        Icon: EditIcon,
    },
    {
        path: Section.DEMO,
        element: <DemoBatchPage />,
        Icon: PreviewIcon,
    },
    {
        path: Section.TO_ANNOTATE,
        element: <ImagesToAnnotatePage />,
        Icon: FormatShapesIcon,
    },
    {
        path: Section.PARTNERS,
        element: <PartnersPage />,
        Icon: PeopleAltRoundedIcon,
    },
];

/** Pages displayed to all logged out users. */
const LOGGED_OUT_ROUTES: RouteObject[] = [
    {
        path: Section.LOGIN,
        element: <LoginPage />,
    },
];

/** Pages for users that are not associated to any partner (unauthorized) */
const NO_PARTNER_ROUTES: MainPage[] = [
    {
        path: Section.NO_PARTNER,
        element: <NoPartnerPage />,
        Icon: EmojiPeopleIcon,
        label: "welcome"
    },
];

function getAllRoutes(): MainPage[] | RouteObject[] {
    return [
        {
            path: "*",
            element: <LoggedInLayout key="logged-in-layout" />,
            children: [
                ...LOGGED_IN_ONLY_ROUTES,
                SETTINGS_ROUTE,
                SINGLE_IMAGE_ROUTE,
                ...ADMIN_ONLY_ROUTES,
                ...NO_PARTNER_ROUTES,
            ],
        },
        {
            element: <LoggedOutLayout key="logged-out-layout" />,
            children: LOGGED_OUT_ROUTES,
        },
    ];
}

/**
 * Retrieves the name of the current page and the icon so that they can be displayed to user.
 */
export const useRoute: (pathname: string, loggedIn: boolean) => { path: string, Icon: AppIcon | undefined } = (pathname, loggedIn) => {
    const path = pathname.substring(1).split("/"); // all pathname components
    const allRoutes = getAllRoutes(); // all top level routes
    let routes: MainPage[] | RouteObject[] = loggedIn ? [...allRoutes[0].children!] : [...allRoutes[1].children!]; // either logged in routes or logged out

    /** Page icon to be displayed to client. */
    let Icon: AppIcon | undefined;

    /** Current route. */
    let route: MainPage | RouteObject | undefined = undefined;

    for (let pathPart of path) { // look for nested route component by component
        route = routes.find(r => r.path === pathPart) as MainPage | RouteObject;
        if (!route) route = routes.find(r => r.path === "*") as MainPage | RouteObject;
        if (!route) continue; // for example if it's an object ID
        if ("Icon" in route) Icon = route.Icon;
        if (!route.children) break; // deepest level of nesting
        routes = route.children; // iterate over route's nested children
    }

    return {
        path: route?.path || "",
        Icon: Icon,
    };
}