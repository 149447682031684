import AddressesWithMostErrors from "components/_include/Charts/AddressesWithMostErrors/AddressesWithMostErrors";
import { UNNAMED_ROAD, getPointsBoundsAndCenter } from "helpers/geo";
import { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector, usePartnerID } from "store/hooks";
import { BatchesMapActions } from "store/reducers/batches/map";
import { PlacedBatchesActions } from "store/reducers/batches/places";
import { selectAllPlacedBatches } from "store/reducers/batches/places";
import SavedAddressesController from "controllers/saved_addresses";
import { useTranslation } from "react-i18next";
import { Namespace } from "locales/translations";
import { PositionedBatch } from "models/Batch";

const NUMBER_LOADED = 10;
const NUMBER_DISPLAYED = 5;

export default function WorstAddressesList() {

    const { t } = useTranslation([Namespace.CHARTS, Namespace.WASTES]);

    const partnerID = usePartnerID();

    const batchesByPlace = useAppSelector(selectAllPlacedBatches);

    const worstBatchesByPlace = useMemo(() => batchesByPlace.slice(0, NUMBER_LOADED), [batchesByPlace]);
    const selectedErrorType = useAppSelector((state) => state.selectedErrorType.errorType);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (partnerID && worstBatchesByPlace.length > 0) {
            dispatch(SavedAddressesController.list(partnerID, worstBatchesByPlace.map(place => place.addressKey)));
        }
    }, [dispatch, partnerID, worstBatchesByPlace]);

    const onAddressClick = useCallback((addressKey: string) => {
        // scroll to map
        const batchesMap = document.getElementById("batches-map");
        if (batchesMap) {
            window.scroll({
                top: batchesMap.offsetTop + batchesMap.offsetHeight / 2,
                behavior: "smooth",
            });
        }

        // show address on the map
        dispatch(PlacedBatchesActions.selectPlace(addressKey));

        // zoom on address
        const batches = batchesByPlace.find(addressStats => addressStats.addressKey === addressKey)?.batches as PositionedBatch[] | undefined;
        if (batches) {
            dispatch(BatchesMapActions.setMapBounds(getPointsBoundsAndCenter(
                batches.map(({ position: { latitude, longitude } }) => ({ lat: latitude, lng: longitude }))
            )));
        }
    }, [batchesByPlace, dispatch]);

    const data = useMemo(() => {
        return worstBatchesByPlace
            .filter(p => p.address.street !== UNNAMED_ROAD)
            .filter(p => p.errorsCount > 0) 
            .slice(0, NUMBER_DISPLAYED);
    }, [worstBatchesByPlace]);

    const title = selectedErrorType
        ? t('addresses_with_most_errors.title_with_error', {
            ns: Namespace.CHARTS,
            trashType: t(selectedErrorType, { ns: Namespace.WASTES }),
        })
        : t('addresses_with_most_errors.title');

    return (
        <AddressesWithMostErrors
            title={title}
            data={data}
            height={(theme) => theme.spacing(39)}
            px={3}
            onAddressClick={onAddressClick}
        />
    )
}