import { DbCollection } from 'constants/db';
import urls from 'constants/urls';
import { User as FirebaseUser, getAuth, sendSignInLinkToEmail, signInWithCustomToken, signInWithEmailLink } from 'firebase/auth';
import { listDocs } from 'helpers/db';
import i18next from 'i18next';
import { Namespace } from 'locales/translations';
import { UserClaims } from 'models/User';
import { stringifyUrl } from 'query-string';
import { hideMessage, showMessage } from 'store/reducers/snacks';
import { UserActions } from 'store/reducers/user';
import { AppDispatch } from 'store/store';
import { CodeError, handleAPIResponse } from './actions';

const signInWithToken = (inviteToken: string) => async (dispatch: AppDispatch) => {
    dispatch(UserActions.startLoading());

    const SIGN_IN_URL = `${urls.API}/user/sign-in`;

    try {
        // validate the pin/password with the server
        const { token }: { token: string } = await fetch(`${SIGN_IN_URL}?token=${inviteToken.trim()}`)
            .then(handleAPIResponse);
        const authInstance = getAuth();

        await signInWithCustomToken(authInstance, token);

        // NB: dispatching of the user data to the store will be done from Firebase auth callback
    }
    catch (e) {
        const error = e as CodeError | Error;
        console.error("Failed signing in", error);
        dispatch(UserActions.setError(error.message));
        dispatch(showMessage({
            variant: "error",
            message: i18next.t("invalid_password", { ns: Namespace.SNACKS }),
            moreInfo: error.message,
        }));
    }
};

/** 
 * Sends a magic link to the provided email address for sign-in.
 * @param email - The email address of the user.
 */
const requestMagicLink = (email: string, ) => async (dispatch: AppDispatch) => {
    dispatch(UserActions.startLoading());

    try {
        const authInstance = getAuth();

        // URL of redirection when clicking on the magic link from the user's mailbox
        const linkURL = stringifyUrl({ 
            url: window.location.href,
            query: {
                email: email,
            },
        });

        // send magic link to the user's email
        await sendSignInLinkToEmail(authInstance, email, {
            url: linkURL,
            handleCodeInApp: true,
        });

        // display success message to user
        dispatch(showMessage({
            variant: "success",
            message: i18next.t("request_magic_link.success", { ns: Namespace.SNACKS, email }),
        }));
        dispatch(UserActions.stopLoading());
    }
    catch (e) {
        const error = e as CodeError | Error;
        console.error("Failed signing in", error);
        dispatch(UserActions.setError(error.message));
        dispatch(showMessage({
            variant: "error",
            message: i18next.t("invalid_link", { ns: Namespace.SNACKS }),
            moreInfo: error.message,
        }));
    }
};

/** 
 * Sign in using a magic link sent to the user's email address.
 * @param email - The email address of the user.
 * @param link - The link to the current user dashboard
 */
const signInWithMagicLink = (email: string, link: string) => async (dispatch: AppDispatch) => {
    dispatch(UserActions.startLoading());

    const authInstance = getAuth();

    try {
        await signInWithEmailLink(authInstance, email, link);
        
        // NB: dispatching of the user data to the store will be done from Firebase auth callback
    }
    catch (e) {
        const error = e as CodeError | Error;
        console.error("Failed signing in", error);
        dispatch(UserActions.setError(error.message));
        dispatch(showMessage({
            variant: "error",
            message: i18next.t("invalid_signin_link", { ns: Namespace.SNACKS }),
            moreInfo: error.message,
        }));
    }
};

const setUser = (firebaseUser: FirebaseUser) => async (dispatch: AppDispatch) => {
    if (firebaseUser) {
        const idTokenResult = await firebaseUser.getIdTokenResult();
        const claims = idTokenResult.claims as UserClaims;
        dispatch(UserActions.setUser({
            ...claims,
            email: firebaseUser.email,
        }));
        dispatch(hideMessage()); // hide error alerts if user tried to sign in with  
    }
}

const getLoginLink = async (partnerID: string) => {
    const matchingInvitations = await listDocs([DbCollection.PARTNERS, partnerID, DbCollection.INVITATIONS], []);

    if (matchingInvitations.length > 0) {
        const token = matchingInvitations[0].id;
        return `${process.env.REACT_APP_DASHBOARD_URL}/login?invitation=${token}`;
    }

    return null;
}

const UserController = {
    signInWithToken,
    requestMagicLink,
    signInWithMagicLink,
    setUser,
    getLoginLink,
};

export default UserController;